@import "../../../variables/type";
@import "../../../variables/color";
@import "../../../variables/layout";

.error-state {
  color: $color-black;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    width: 50%;
    padding-bottom: 1rem;
  }

  &__header {
    @include header-4;
    font-weight: 400;
    padding-bottom: 1rem;
    text-align: center;

    & span {
      display: block;
      font-weight: 700;
    }
  }

  &__body {
    @include body-1;
    text-align: center;
    color: $color-gray-80;
    padding-bottom: 1rem;
  }

  &__controls {
    width: 67%;

    & .MuiButton-root {
      margin-bottom: 1rem;
    }
  }
}

@include breakpoint("lrg") {
  .error-state {
    &__img {
      width: 25%;
    }

    &__body {
      width: 50%;
    }

    &__controls {
      width: 33%;
    }
  }
}
