@import "../../../variables/type";
@import "../../../variables/color";
@import "../../../variables/layout";

.menu-item-group {
  border-top: 2px solid $color-gray-10;
  padding-top: 2rem;

  & .header-4 {
    font-weight: 500;
    color: $color-gray-100;
    padding-bottom: 1rem;
  }

  & .menu-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 2rem;
    background-color: #fff;
    border-top: none;
    margin-bottom: 2rem;
  }
}

@include breakpoint("lrg") {
  .menu-item-group {
    & .menu-items {
      margin: 1rem 0rem 1rem 0rem;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;
    }
  }
}
