@import "../../../variables/layout";
@import "../../../variables/type";
@import "../../../variables/color";

.update-menu-form {
  & .body-1 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-weight: $font-weight-medium;
    color: $color-gray-100;
  }

  & .MuiButton-root {
    width: 100%;
  }

  &__date-fields {
    & .MuiButton-root {
      height: 3rem;
      margin-top: 0.75rem;
      width: 100%;
      color: $color-gray-80;
      border: 1px solid $color-gray-20;
      font-weight: $font-weight-regular;

      &:hover {
        color: $color-gray-100;
      }
    }
  }

  &__delivery-dates {
    display: flex;
    flex-direction: column;
    &.MuiFormControl-root {
      min-width: 8rem;
    }
  }

  &__filter {
    background-color: $color-gray-10;
    border: 1px solid $color-gray-20;
    padding: 0.5rem;
    border-radius: 4px 4px 0px 0px;

    & .MuiButton-root {
      width: auto;
      text-transform: none;
    }
  }

  &__text-filter {
    border: 1px solid $color-gray-20;
    width: 100%;
    padding: 1rem 0.5rem;
    border-radius: 2px;
    margin-bottom: 0.5rem;
  }

  &__sub-filter {
    padding-top: 1rem;
  }

  &__meal-selection-panel {
    max-height: 16.5rem;
    overflow-y: scroll;
    border: 1px solid $color-gray-10;
    margin-bottom: 2rem;
  }

  @include breakpoint("med") {
    & .MuiButton-root {
      width: auto;
    }

    &__date-fields {
      & .MuiFormControl-root {
        width: calc(33% - 1rem);
      }

      & .MuiButton-root {
        width: 33%;
        height: 3.5rem;
        margin-top: 1rem;
      }
    }
  }

  @include breakpoint("lrg") {
    // max-width: 70%;
    &__date-fields {
      display: flex;
      align-items: top;

      & .MuiFormControl-root {
        width: auto;
      }

      & .MuiButton-root {
        height: 3.5rem;
        margin-top: 1rem;
      }
    }

    &__delivery-dates {
      flex-direction: row;
      width: 100%;
    }

    &__filter-main {
      display: flex;

      & .MuiButton-root {
        height: 2.5rem;
      }
    }

    &__sub-filter {
      padding: 0.5rem;
      display: flex;
      align-items: flex-end;

      & .MuiFormControl-root {
        width: 12rem;
        margin-right: 2rem;
      }
    }

    &__text-filter {
      padding: 0.5rem;
      height: 2.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0rem;
    }
    &__meal-selection-panel {
      max-height: 24rem;
    }
  }
}
