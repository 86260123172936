@import "../../variables/layout";
@import "../../variables/type";
@import "../../variables/color";

.meal-details-unavailable {
  padding: 0rem 1rem;
  margin: 0rem 0rem 2rem 0rem;
  // background-color: $color-gray-05;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: $color-gray-20;
  }

  &__header {
    font-weight: 700;
    line-height: 1.5em;
    margin-bottom: 0.125rem;
    color: $color-gray-80;
  }

  &__body {
    font-size: 0.875rem;
    line-height: 1.5em;
    color: $color-gray-60;
  }
}

@include breakpoint("lrg") {
  .meal-details-unavailable {
    padding: 0rem 3rem 0rem 1rem;

    &__body {
      font-size: 1rem;
    }
  }
}
