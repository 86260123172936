@import "../../../../variables/layout";
@import "../../../../variables/color";

.add-zip-code {
  padding-bottom: 1rem;

  margin-bottom: 2rem;
  &-input {
    border-bottom: 1px solid $color-gray-10;
    padding-bottom: 1rem;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
    }

    &__form {
      display: flex;

      & button {
        margin-left: 0.5rem;
      }
    }

    &__error {
      color: $color-error;
      padding-top: 1rem;
    }
  }
}
