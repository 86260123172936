@import "../../../variables/layout";
@import "../../../variables/type";
@import "../../../variables/color";

.faq {
  border-bottom: 1px solid $color-gray-20;
  width: 100%;
  position: relative;

  &__toggle {
    position: relative;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
    padding: 1rem 3rem 1rem 0.5rem;
    color: $color-black;

    &:after {
      color: $color-gray-80;
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 300;
      position: absolute;
      right: 1rem;
      top: 0.75rem;
    }

    &:hover:after {
      color: $color-black;
    }

    &--expanded {
      &:after {
        content: "–";
      }
    }

    &--collapsed {
      &:after {
        content: "+";
      }
    }
  }
  &__content {
    overflow: hidden;
    box-sizing: border-box;
    max-height: 0;
    transition: max-height 0.125s ease;

    & p {
      padding: 0.5rem 0.5rem 2rem 0.5rem;
    }

    & img {
      padding: 0.5rem;
      width: 100%;
      height: auto;
    }
  }
}

@include breakpoint("med") {
}

@include breakpoint("lrg") {
  .faq {
    &__toggle:hover {
      background-color: $color-gray-05;
      cursor: pointer;
    }
  }
}
