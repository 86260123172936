@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.express-thank-you {
  &__header {
    border-bottom: 1px solid $color-gray-10;

    h1 {
      font-size: 1.25rem;
      padding-bottom: 1rem;

      & span {
        font-weight: 300;
      }
    }
  }

  &__checkmark {
    width: 4rem;
    margin-bottom: 0.5rem;

    & svg {
      width: 100%;
    }
  }

  &__message {
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__message-content {
    text-align: center;
  }

  &__message-header {
    font-weight: 700;
    color: $color-gray-100;
    padding-bottom: 0.5rem;
  }

  &__message-body {
    font-size: 1rem;
    line-height: 1.25em;
    color: $color-gray-80;
  }

  &__order-details {
    padding: 1rem;
    background-color: $color-gray-10;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  &__order-detail {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    line-height: 2em;
    font-weight: 600;
    color: $color-gray-100;

    & span {
      font-weight: 400;
    }
  }

  &__questions {
    padding: 2rem;
    border: 1px solid $color-gray-10;
    display: flex;
    flex-direction: column;
    align-items: center;

    & h2 {
      text-align: center;
      width: 95%;
      font-size: 1.25rem;
      line-height: 1.2em;
      padding-bottom: 1rem;
      color: $color-gray-80;
      font-weight: 400;
    }
  }
}

@include breakpoint("lrg") {
  .express-thank-you {
    display: flex;
    flex-direction: column;
    // align-items: center;

    &__header {
      width: 100%;
      padding: 1rem 0rem;

      h1 {
        font-size: 1.5rem;
        padding-bottom: 0rem;
      }
    }

    &__page-content {
      display: flex;
    }

    &__main {
      width: 70%;
      margin-right: 2rem;
    }

    &__message-header {
      font-size: 2rem;
      padding-bottom: 1rem;
    }

    &__instructions {
      padding-bottom: 0rem;
    }

    &__questions {
      border-top: none;
      padding-top: 8rem;
    }
  }
}
