@import "../../../variables/_layout.scss";
@import "../../../variables/_type.scss";
@import "../../../variables/_color.scss";

.order-manager {
}

@include breakpoint("lrg") {
  .order-manager {
    & .MuiTabs-root {
      width: 50%;
    }
  }
}
