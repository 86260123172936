.brento-modal {
  z-index: 5000;

  & .MuiIconButton-root {
    position: absolute;
    right: 0;
  }

  &__component {
    margin-top: 2rem;
    padding: 1rem;
  }

  &__modal-controls .MuiButton-containedPrimary {
    margin-bottom: 0.5rem;
  }
}
