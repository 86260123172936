@import "../../../variables/color";
@import "../../../variables/layout";
@import "../../../variables/type";

.checkout-form {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &__fieldset {
    padding-bottom: 2rem;

    & .brento-text-input {
      margin-bottom: 1rem;
    }

    & .MuiFormControl-root .MuiFormGroup-root {
      margin-top: -0.5rem;
    }

    &-label {
      padding-bottom: 1.5rem;
      font-weight: 600;
      color: $color-gray-100;
    }
  }

  &__discount-code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    & .brento-text-input {
      margin-bottom: 0rem;
      margin-right: 0.25rem;

      & .MuiOutlinedInput-root.MuiInputBase-sizeSmall input {
        padding: 14px;
      }
    }

    & .MuiLoadingButton-root.MuiButton-outlined {
      flex: none;
      margin-bottom: auto;
    }
  }

  &__city-state-row {
    display: flex;

    & .brento-text-input {
      margin-right: 0.5rem;
      width: 67%;
    }

    & .brento-select {
      width: 33%;

      & label {
        transition: none;
      }
    }
  }

  &__special-instructions {
    & .brento-text-input {
      & .MuiInputBase-root {
        height: 12rem;
        align-items: flex-start;
      }
    }
  }

  & .alert-message {
    margin-bottom: 1rem;
  }

  &__card-input {
    padding: 1rem;
    width: 100%;
  }

  &__card-input.checkout-form__card-input--error {
    border: 1px solid $color-error;
    border-radius: 2px;
    padding: 1rem;
  }

  &__cc-details-row {
    display: flex;
    justify-content: space-between;

    & .brento-text-input {
      flex-basis: calc(50% - 0.25rem);
    }
  }

  &__billing-address-field {
    margin-top: 1rem;
  }

  & .MuiButton-contained {
    margin-bottom: 1rem;
  }

  .checkout-alert-error {
    padding: 0.25rem 0rem;
    position: relative;

    &:before {
      content: "×";
      color: $color-error;
      margin-right: 0.25rem;
    }
  }
}

@include breakpoint("lrg") {
  .checkout-form {
    padding-top: 0rem;
    &__city-state-row {
      display: flex;

      & .brento-text-input {
        margin-right: 0.5rem;
        width: 67%;
      }

      & .brento-select {
        width: 33%;
      }
    }
  }
}
