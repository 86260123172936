@import "../../variables/type";
@import "../../variables/color";

.meal-details-hero {
  margin-top: 0rem;

  &__image {
    width: 100%;
    height: 16rem;
    object-fit: cover;
  }

  &__title {
    margin: 0.5rem 0rem 1rem 0rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    &-header {
      @include header-4;
    }

    &-sub-head {
      @include header-5;
      @include font-light;
    }
  }

  & .display-price {
    padding: 0.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: $color-gray-80;
    display: flex;

    &__dollar-sign {
      font-weight: 400;
      font-size: 0.75em;
      margin-top: 0.125em;
    }

    &__cents {
      font-size: 0.75em;
      margin-top: 0em;
      font-weight: 600;
    }
  }

  &__dietary-chips {
    display: flex;
    // border: 1px solid red;
    padding-bottom: 1rem;
  }

  &__description {
    color: $color-gray-100;
    padding-bottom: 1rem;
    font-weight: 400;
  }
}

@include breakpoint("med") {
  .meal-details-hero {
    margin-top: 1rem;

    &__image {
      width: 100%;
      height: 16rem;
      object-fit: cover;
    }
  }
}

@include breakpoint("lrg") {
  .meal-details-hero {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;

    &__image {
      width: 0;
      flex-basis: 45%;
      height: 24.125rem;
    }

    &__title {
      flex-direction: column;
    }

    &__content {
      flex-basis: 50%;

      & .display-price {
        float: none;
        font-size: 2rem;
        margin-top: 1rem;
        padding: 0rem;
        margin-bottom: 1rem;

        &__dollar-sign {
          font-size: 0.5em;
          margin-top: 0.25em;
        }

        &__cents {
          font-size: 0.5em;
          margin-top: 0.25em;
        }
      }
    }

    &__title {
      margin-bottom: 1rem;
      float: none;

      &-header {
        @include header-2;
      }

      &-sub-head {
        @include header-3;
      }
    }

    &__dietary-chips {
      padding-bottom: 2rem;
    }

    &__description {
      padding-bottom: 2rem;
    }
  }
}
