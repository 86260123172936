@import "../../../../variables/type";
@import "../../../../variables/color";

.social-icons {
  display: flex;
  justify-content: center;
  height: 4.5rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid $color-gray-100;
  width: 100%;

  & .social-icon {
    // width: 4.5rem;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0rem 0.5rem;
    display: block;

    &:hover {
      & circle {
        fill: #fff;
      }

      & .social-icon--facebook {
        & path {
          fill: #1778f2;
        }
      }

      & .social-icon--instagram {
        & path {
          fill: url(#instagram-gradient);
        }
      }

      & .social-icon--youtube {
        & path {
          fill: #ff0000;
        }
      }

      & .social-icon--yelp {
        & path {
          fill: #c41200;
        }
      }
    }

    & circle {
      fill: $color-black;
    }

    & path {
      fill: $color-gray-20;
      fill-rule: evenodd;
      clip-rule: evenodd;
    }
  }
}

@include breakpoint("lrg") {
  .social-icons {
    justify-content: flex-end;
    width: unset;
    height: 2.5rem;

    & .social-icon {
      width: 2rem;
      height: 2rem;

      &:last-child {
        margin-right: 0rem;
      }
    }
  }
}
