@import "../../../../variables/layout";
@import "../../../../variables/color";

.gift-card-table {
  & .hide-sm {
    display: none;
  }

  &__title {
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-weight: 500;
  }

  & .empty-state {
    padding: 4rem 0rem;
    text-align: center;
  }

  &__card-count {
    font-size: 0.875rem;
    color: $color-gray-80;
  }

  &__headers {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    background-color: $color-gray-10;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid $color-gray-20;

    & p {
      flex-basis: 25%;
      font-size: 0.75rem;
      color: $color-gray-80;
      font-weight: 700;
      &:last-child {
        flex-basis: 10%;
      }
    }
  }

  &__list {
    border: 1px solid $color-gray-20;
    border-radius: 0px 0px 4px 4px;
    max-height: 396px;
    overflow-y: scroll;
  }

  & .gift-card-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid $color-gray-20;
    font-size: 0.75rem;

    &:last-child {
      border-bottom: none;
    }

    &__discount-code {
      display: inline;
      font-weight: 700;
      color: $color-gray-100;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__field {
      color: $color-gray-80;
      & .from-now {
        font-size: 0.75rem;
        background-color: $color-gray-10;
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        font-weight: 500;
      }
    }

    &__link {
      flex-basis: 25%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        p {
          color: $color-gray-80;
        }
      }
      & p {
        text-decoration: underline;
        width: 100%;
        text-align: left !important;
      }
    }

    & p {
      flex-basis: 25%;
      &:last-child {
        flex-basis: 10%;
        text-align: center;
        & span {
          padding: 0.25rem 0.5rem;
          border-radius: 4px;
          font-weight: 500;

          &.yes {
            background-color: $color-secondary-lightest;
          }

          &.no {
            background-color: $color-gray-10;
          }
        }
      }
    }
  }

  @include breakpoint("lrg") {
    & .hide-sm {
      display: inherit;
    }

    &__controls {
      display: flex;
      padding: 1rem 0rem;
      justify-content: space-between;
    }

    &__headers {
      & p {
        font-size: 0.875rem;
      }
    }

    & .gift-card-item {
      font-size: 0.875rem;
    }
  }
}
