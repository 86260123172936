@import "../../../../variables/color";
@import "../../../../variables/type";
@import "../../../../variables/layout";

.menu-item__dietary-tag {
  color: $color-gray-80;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem;
  margin-right: 0.25rem;
  border-radius: 4px;
  background-color: $color-gray-10;

  &--hover {
    & .menu-item__dietary-tag-tooltip {
      visibility: visible;
      top: -2.5rem;
      opacity: 1;
    }
  }

  @include breakpoint("lrg") {
    & {
      padding: 0.5rem;

      &:hover {
        cursor: help;
        background-color: $color-gray-20;
      }
    }
  }
}
