@import "../../../../../variables/color";
@import "../../../../../variables/layout";
@import "../../../../../variables/type";

.promo-code-email-modal {
  & .header-5,
  & &__instructions {
    padding-bottom: 1rem;
  }

  & &__greeting {
    padding-bottom: 0.5rem;
  }

  & .header-5,
  & &__greeting {
    font-weight: 700;
  }

  & &__instructions {
    color: $color-gray-80;
    & span {
      color: $color-gray-100;
      font-weight: 700;
    }
  }
}

@include breakpoint("lrg") {
  .promo-code-email-modal {
    &__main {
      width: 480px;
    }
  }
}
