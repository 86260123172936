@import "../../../variables/_color.scss";
@import "../../../variables/_type.scss";
@import "../../../variables/layout";

.section {
  padding: 2rem 0rem 4rem 0rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &--white {
    background-color: #fff;
  }

  &--light {
    background-color: $color-gray-05;
  }

  &--dark {
    background-color: $color-gray-100;
  }

  &--none {
    background-color: transparent;
  }
}

.section__title {
  padding: 1rem 0rem 2rem 0rem;
  font-family: $font-primary;
  font-weight: 700;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include header-4;

  &:before,
  &:after {
    content: " ";
    margin: 0 1rem 0 0;
    flex: 1 0 1rem;
    border-bottom-width: 2px;
    border-bottom-style: dotted;
  }

  &:after {
    margin: 0 0 0 1rem;
  }
}

.section__title--white {
  color: $color-black;
  &:before,
  &:after {
    border-color: $color-gray-10;
  }
}

.section__title--none {
  color: $color-black;
  &:before,
  &:after {
    border-color: $color-gray-10;
  }
}

.section__title--light {
  color: $color-black;
  &:before,
  &:after {
    border-color: $color-gray-40;
    opacity: 0.25;
  }
}

.section__title--dark {
  color: $color-gray-05;
  &:before,
  &:after {
    border-color: $color-gray-80;
  }
}

@include breakpoint("lrg") {
  .section__title {
    &:before,
    &:after {
      border-bottom-width: 2px;
    }
  }
}
