@import "../../../variables/_layout.scss";
@import "../../../variables/_type.scss";
@import "../../../variables/_color.scss";

.menu-manager {
  &-header {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    &__title {
      @include header-4;
      color: $color-black;
      margin-bottom: 0.5rem;
    }

    &__label {
      margin-bottom: 2rem;
      color: $color-gray-80;
    }

    &__date-field {
      font-size: 0.875rem;
      color: $color-gray-60;
    }

    &__date-label {
      font-weight: $font-weight-bold;
      margin-bottom: 0.25rem;
      color: $color-gray-80;
    }

    @include breakpoint("lrg") {
      margin-top: 2rem;
      justify-content: space-between;
      align-items: baseline;
    }
  }
}
