@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.menu-category-link {
  display: block;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &__image {
    position: relative;
    width: 100%;
    height: 8rem;
    object-fit: cover;
    z-index: 99;
  }

  &__content {
    position: absolute;
    z-index: 100;
    top: 20%;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.67));
    transition: all 0.125s ease-in-out;

    & p {
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      font-family: $font-primary;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5;
      background-color: rgba(0, 0, 0, 0.375);
      box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.375);
      // text-decoration: underline dotted $color-gray-60;
      color: $color-gray-10;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   right: -0.75rem;
      //   top: calc(50% - 3px);
      //   width: 6px;
      //   height: 6px;
      //   border-radius: 1px;
      //   border-right: 2px solid $color-gray-20;
      //   border-top: 2px solid $color-gray-20;
      //   transform: rotate(45deg);
      // }
    }
  }
}

@include breakpoint("lrg") {
  .menu-category-link {
    &__image {
      height: 14rem;
    }

    &__content {
      & p {
        left: 0rem;
        bottom: 0rem;
        padding: 0.5rem 1rem 1rem 2rem;
        font-size: 1.5rem;
        // background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      }
    }

    &:hover {
      .menu-category-link__content {
        top: 0%;

        & p {
          color: #fff;
          text-decoration: underline dotted $color-gray-40;
        }
      }
    }
  }
}
