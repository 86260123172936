@import "../../../../variables/layout";
@import "../../../../variables/color";

.analytics-filter {
  padding: 1rem 0rem;
  border-bottom: 1px solid $color-gray-10;

  &__header {
    padding: 1rem 0rem 0rem 0rem;
    & .header-4 {
      font-weight: 300;
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;

    & .MuiFilledInput-root {
      border-radius: 4px;
      max-width: 200px;
    }

    & .dynamic-filters {
      padding: 1rem 0rem;
      display: flex;
    }
  }
}
