@import "../../../../variables/layout";
@import "../../../../variables/color";

.analytics-table {
  border: 2px solid $color-gray-10;
  border-radius: 4px;
  height: 100%;
  max-height: 400px;
  overflow-y: scroll;
  &__row {
    display: flex;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid $color-gray-10;
    font-size: 0.875rem;
    & .additional-values {
      display: none;
    }
    & .row-label {
      flex-basis: 50%;
      font-weight: 500;
      color: $color-gray-80;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &__header {
    display: flex;
    display: flex;
    padding: 1rem 0.5rem;
    border-bottom: 2px solid $color-gray-10;
    font-size: 0.875rem;
    background-color: $color-gray-10;
    color: $color-gray-100;
    font-weight: 500;
    & .header-label {
      text-transform: capitalize;
      flex-basis: 50%;
    }
    & .additional-values {
      display: none;
    }
  }
}

@include breakpoint("lrg") {
  .analytics-table {
    &__row {
      & .row-value {
        flex-basis: 33%;
      }
      & .additional-values {
        display: flex;
        justify-content: space-between;
        flex-basis: 33%;
        & .row-value {
          flex-basis: 33%;
          text-align: center;
        }
      }
    }

    &__header {
      & .row-value {
        flex-basis: 33%;
      }
      & .additional-values {
        display: flex;
        justify-content: space-between;
        flex-basis: 33%;
        & .row-value {
          flex-basis: 33%;
        }
      }
    }
  }
}
