@import "../../variables/color";
@import "../../variables/type";
@import "../../variables/layout";

.privacy-policy {
  color: $color-black;
  padding-bottom: 4rem;
  padding-top: 2rem;

  h1 {
    @include header-1;
    padding-bottom: 1rem;
  }

  h2 {
    @include header-3;
    font-weight: 400;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  h3 {
    font-size: 1.25rem;
    padding-bottom: 1rem;
  }

  h4 {
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }

  p {
    @include body-2;
    padding-bottom: 1rem;
    color: $color-gray-100;
  }

  li {
    @include body-2;
    padding-bottom: 1rem;
    list-style-type: square;
    margin-left: 1.5rem;

    p {
      padding-bottom: 0rem;
    }
  }
}

@include breakpoint("lrg") {
  .privacy-policy {
    .inner-container {
      width: 67%;
    }

    h2 {
      @include header-4;
    }

    h3 {
      font-size: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    h4 {
      font-size: 1.25rem;
      padding-bottom: 0.5rem;
      padding-top: 1rem;
    }
  }
}
