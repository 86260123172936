@import "../../variables/type";
@import "../../variables/color";

.dietary-chip {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.875rem;
  color: $color-gray-100;
  font-weight: 500;
  line-height: 1em;
  border: 1px solid $color-gray-20;
  border-radius: 1rem;
  background-color: $color-gray-05;

  &__icon {
    width: 1rem;
    fill: none;
    stroke-width: 3px;
    margin-right: 0.25rem;
    stroke: $color-gray-60;
  }
}

@include breakpoint("lrg") {
  .dietary-chip {
    padding: 0.75rem;
    border-radius: 2rem;
    margin-right: 1rem;

    &__icon {
      margin-right: 0.5rem;
    }
  }
}
