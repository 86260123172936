@import "../../../variables/layout";
@import "../../../variables/type";
@import "../../../variables/color";

.about-content {
  &__img {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    & img {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 0.5rem;
      margin-right: 1rem;
    }
  }
  &__caption {
    font-size: 0.875rem;
    color: $color-gray-80;
  }

  &__name {
    display: block;
    margin-bottom: 0.5rem;
    color: $color-gray-100;
  }
  &__text {
    &-opener {
      font-weight: 500;
      margin-bottom: 1rem;
      color: $color-black;
      font-family: $font-primary;
    }

    & .body-1 {
      margin-bottom: 1rem;
      color: $color-gray-100;
    }
  }
}

@include breakpoint("lrg") {
  .about-content {
    display: flex;
    justify-content: center;
    padding: 2rem 0rem;

    &__img {
      width: 100%;
      margin-top: 2rem;
    }

    &__text {
      flex-basis: calc(67% - 2rem);

      &-opener {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
}
