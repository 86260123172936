@import "../../../../variables/layout";
@import "../../../../variables/color";

.hide-sm {
  display: none;
}

.gift-card-details-table {
  &__section-header {
    padding: 3rem 0rem 0.5rem 0rem;
    color: $color-gray-100;
  }
  &__row {
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem;
    border-bottom: 1px solid $color-gray-20;
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    flex-basis: 33%;
    margin-right: 2rem;
    padding-bottom: 0.5rem;
    color: $color-gray-80;
  }

  &__value {
    color: $color-black;

    & .from-now {
      margin-left: 0.5rem;
      background-color: $color-gray-10;
      color: $color-gray-80;
      border-radius: 8px;
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
    }
  }
}

.order-details-table {
  &__header {
    display: flex;
    background-color: $color-gray-10;
    padding: 0.5rem 0.5rem;
    &-label {
      color: $color-gray-80;
      font-size: 0.875rem;
      font-weight: 500;
      flex-basis: 50%;
      &:first-child {
        margin-right: 2rem;
      }
    }
  }

  &__table {
    border: 1px solid $color-gray-10;
    border-radius: 2px;
  }

  &__row {
    display: flex;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid $color-gray-10;
    &:last-child {
      border-bottom: none;
    }
  }

  &__value {
    flex-basis: 50%;
    color: $color-gray-80;
    &:first-child {
      font-size: 0.875rem;
      color: $color-gray-100;
      margin-right: 2rem;
    }
  }

  .empty-state {
    color: $color-gray-60;
  }
}

@include breakpoint("med") {
  .hide-sm {
    display: inherit;
  }

  .gift-card-details-table {
    &__row {
      flex-direction: row;
    }
    &__section-header {
      padding: 3rem 0rem 1rem 0rem;
    }
    &__label {
      flex-basis: 25%;
      font-size: 1rem;
      padding-bottom: 0rem;
    }

    &__value {
      & .from-now {
        margin-left: 2rem;
      }
    }
  }

  .order-details-table {
    &__header {
      &-label {
        flex-basis: 25%;
      }
    }
    &__value {
      flex-basis: 25%;
    }
  }
}
