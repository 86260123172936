@import "../../../variables/color";
@import "../../../variables/layout";
@import "../../../variables/type";

.checkout-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  &__divider {
    color: $color-gray-20;
    position: relative;

    &:before {
      content: "/";
    }
  }

  &__step {
    position: relative;
    font-size: 0.75rem;
    padding: 0.5rem 0rem;
    border: none;
    font-weight: 500;
    text-align: left;
    background-color: transparent;
  }

  &__step--active {
  }

  &__step--inactive {
    color: $color-secondary-dark;
  }
}

@include breakpoint("lrg") {
  .checkout-stepper {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    width: calc(43% - 3rem);

    &__step {
      font-size: 1rem;
      position: relative;
    }

    &__step--inactive {
      color: $color-secondary-dark;
      &:hover {
        cursor: pointer;
        opacity: 0.85;
      }
      // &:before {
      //   content: "";
      //   position: absolute;
      //   height: 1rem;
      //   width: 1rem;
      //   border-radius: 50%;
      //   background-color: $color-secondary-dark;
      //   top: -0.75rem;
      //   left: calc(50% - 0.5rem);
      // }
    }
  }
}
