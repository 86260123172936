@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.alert-message {
  position: relative;
  padding: 1rem 1rem 1rem 1.25rem;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
  }

  &--error {
    background-color: $color-error-lightest;

    &:before {
      background-color: $color-error;
    }
  }

  &--success {
    background-color: $color-secondary-lightest;

    &:before {
      background-color: $color-secondary;
    }

    & .alert-message__content,
    & .alert-message__header {
      color: $color-secondary-darkest;
    }
  }

  &--info {
    background-color: $color-gray-10;

    &:before {
      background-color: $color-gray-40;
    }
  }

  &__header {
    font-weight: 700;
    padding-bottom: 0.5rem;
  }

  &__content {
    line-height: 1.25em;
  }
}
