@import "../../../variables/color";
@import "../../../variables/layout";

.how-it-works {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  &__step {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  &__illo {
    width: 4.5rem;
    margin-right: 1rem;
  }

  &__content {
    text-align: left;
    flex-basis: 67%;
  }

  &__step-name {
    font-size: 1.25rem;
    margin: 1rem 0rem 0.5rem 0rem;
  }

  &__description {
    font-size: 0.875rem;
    line-height: 1.5em;
    color: $color-gray-80;
  }
}

@include breakpoint("lrg") {
  .how-it-works {
    flex-direction: row;
    margin-top: 2rem;

    &__step {
      position: relative;
      flex-basis: calc(25% - 1rem);
      flex-direction: column;

      &:after {
        content: "";
        position: absolute;
        width: 6rem;
        right: -25%;
        top: 4rem;
        border-bottom: 3px dotted $color-gray-20;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    &__content {
      text-align: center;
    }

    &__illo {
      width: 7.5rem;
      margin: auto;
    }
  }
}
