@import "../../variables/layout";
@import "../../variables/type";

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.MuiFormGroup-root.categories-radio {
  // TODO: Fix below styles in make styles globally
  width: 100% !important;
  padding-bottom: 1rem;
}

.form-container {
  width: 100%;
  animation: fadeIn 0.25s ease-in-out;

  & .edit-meal__title {
    @include header-5;
    color: $color-black;
    margin-bottom: 2rem;

    & span {
      font-weight: $font-weight-light;
    }
  }
}

@include breakpoint("lrg") {
  .form-field-row {
    display: flex;
    // border: 1px solid pink;
    justify-content: space-between;

    &__left {
      flex-basis: 50%;
    }

    &__right {
      flex-basis: 50%;
    }
  }

  .macro-row {
    display: flex;
  }
}
