@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.meal-details {
  animation: fadeIn 0.5s ease-in-out;
  padding-top: 1rem;
}
