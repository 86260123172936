@import "../../variables/layout";

.faqs-content {
  display: block;

  &__title {
    padding-bottom: 2rem;
  }

  &__faq-panel {
    padding-top: 2rem;
  }
}

@include breakpoint("med") {
}

@include breakpoint("lrg") {
  .faqs-content {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;

    &__title {
      flex-basis: 25%;
    }

    &__main {
      flex-basis: 58.33%;
    }

    &__faq-panel {
      padding-top: 2rem;
    }
  }
}
