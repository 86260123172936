@import "../../../../variables/_layout.scss";
@import "../../../../variables/_type.scss";
@import "../../../../variables/_color.scss";

.order-stats {
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & .order-stat {
    padding: 1rem;
    box-shadow: $color-box-shadow-3;
    margin-bottom: 1rem;
    flex-basis: calc(50% - 0.5rem);

    &:last-of-type {
      flex-basis: 100%;
    }

    &__stat {
      font-size: 2rem;
      margin-bottom: 0.5rem;
      font-weight: 700;
    }

    &__label {
      color: $color-gray-60;
    }
  }
}

@include breakpoint("lrg") {
  .order-stats {
    padding: 0rem 0rem 2rem 0.25rem;
    width: 100%;

    & .order-stat {
      flex-basis: calc(33% - 0.25rem);
      margin-bottom: 0rem;

      &:last-of-type {
        flex-basis: calc(33% - 0.25rem);
      }

      &__stat {
        margin-bottom: 1rem;
      }
    }
  }
}
