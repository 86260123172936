@import "../../../variables/_type.scss";
@import "../../../variables/_layout.scss";
@import "../../../variables/_color.scss";

.meal-manager__action-panel {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  border-radius: 16px 16px 0px 0px;
}

.action-panel__selected {
  animation: fadeIn 0.25s ease;
}

.action-panel__top {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.action-panel__details {
  padding: 0.5rem 1rem;
}

.action-panel__meal-thumbnail {
  width: 4rem;
  height: 4rem;
  border-radius: 8px 4px 4px 4px;
  object-fit: cover;
}

.action-panel__meal-name {
  font-size: 1rem;
  font-weight: 600;
  margin: 0.5rem 0rem;
  color: $color-black;
}

.action-panel__date-added {
  // margin-bottom: 1rem;
  font-size: 0.875rem;
  color: $color-gray-60;
}

.action-panel__controls {
  display: flex;
  flex-direction: row;

  & button:first-child {
    margin-right: 0.5rem;
  }
}

.action-panel__confirm-delete-controls {
  display: flex;
  flex-direction: row;

  & button:first-child {
    margin-right: 0.5rem;
  }
}

.action-panel__meal-analytics {
  display: none;
}

.meal-manager__empty-state {
  padding: 1rem;
  color: $color-gray-60;
  font-size: 0.875rem;
  text-align: center;
  margin: 0rem -1rem;

  &__header {
    font-weight: $font-weight-bold;
    color: $color-gray-80;
    margin-bottom: 0.5rem;
  }
}

@include breakpoint("lrg") {
  .meal-manager__action-panel {
    padding: 2rem;
  }

  .action-panel__meal-analytics {
    display: block;
    padding: 2rem 0rem;
    & .meal-analytics {
      &__header {
        font-weight: 700;
        color: $color-gray-100;
        padding-bottom: 1rem;
        border-bottom: 2px solid $color-gray-10;
      }
      &__stat {
        display: flex;
        padding: 1rem 0rem;
        border-bottom: 1px solid $color-gray-10;

        &-label {
          flex-basis: 50%;
          color: $color-gray-80;
        }

        &-value {
          font-weight: 700;
        }
      }
    }
  }

  .action-panel__top {
    flex-direction: column;
    text-align: center;
  }

  .action-panel__meal-thumbnail {
    height: 16rem;
    width: 24rem;
  }

  .action-panel__meal-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0rem 0rem 0.5rem 0rem;
    color: $color-black;
  }

  .action-panel__date-added {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: $color-gray-60;
  }

  .meal-manager__toolbar {
    position: relative;
    margin: 0rem;
    padding: 1rem 0rem 1rem 1rem;
    width: 100%;

    & .meal-count-value {
      font-weight: $font-weight-bold;
    }
  }

  .meal-manager__selection-panel:hover {
    cursor: pointer;
  }

  .action-panel__controls {
    flex-direction: column;

    & button:first-child {
      margin-bottom: 0.5rem;
    }
  }

  .meal-manager__empty-state {
    border-top: none;
    padding: 2rem;
    margin: 0rem;

    &__header {
      font-weight: $font-weight-bold;
      color: $color-gray-80;
      margin-bottom: 0.5rem;
    }
  }
}
