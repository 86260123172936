@import "../../../../../variables/color";
@import "../../../../../variables/type";
@import "../../../../../variables/layout";

.express-thank-you__instructions {
  padding: 1rem 0rem;
  border-top: 1px solid $color-gray-10;

  &-header {
    color: $color-gray-100;
    padding-bottom: 0.5rem;

    & span {
      font-weight: 500;
    }
  }

  & .brento-text-input {
    margin-bottom: 0.5rem;
  }

  & .alert-message {
    margin-top: 0.5rem;
  }

  & .ety__complete {
    background-color: $color-gray-05;
    padding: 1rem;
    border-radius: 4px;
    position: relative;

    & h3 {
      padding-bottom: 1.5rem;
      color: $color-gray-80;
      font-weight: 700;
    }

    & p {
      line-height: 1.5em;
      color: $color-gray-80;
      font-style: italic;
    }

    &-edit-btn {
      height: 2rem;
      width: 4rem;
      border: 1px solid $color-gray-20;
      border-radius: 2px;
      background-color: $color-gray-10;
      color: $color-gray-100;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      transition: all 0.125s ease-in-out;

      &:hover {
        background-color: $color-gray-80;
        border-color: $color-gray-80;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  & .ety__edit-controls {
    & .MuiButton-outlined {
      margin-bottom: 0.5rem;
    }
  }
}

@include breakpoint("lrg") {
  .express-thank-you__instructions {
    padding-bottom: 0rem;

    & .ety__edit-controls {
      display: flex;

      & .MuiButton-outlined {
        margin-bottom: 0rem;
        margin-right: 0.5rem;
      }
    }
  }
}
