@import "../../../variables/type";
@import "../../../variables/color";
@import "../../../variables/layout";

@keyframes fadeAndSlideIn {
  from {
    opacity: 0;
    transform: translateX(2rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
}

.hero {
  background-color: $color-gray-05;
  background-image: url(../../../img/hero1.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;

  &__text {
    position: relative;
    margin: 45% 0rem 0rem 0rem;
    padding: 1rem;
    color: $color-black;
    background-color: #fff;
    box-shadow: $color-box-shadow-1;
    border-radius: 2px;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 0.5rem;
      right: 0;
      top: 0;
      border-left: 2px dotted $color-gray-10;
    }
  }

  & .header-1 {
    font-family: $font-primary;
    color: $color-black;
    font-weight: 700;
  }

  & .eyebrow-1 {
    color: $color-gray-100;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.5em;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: $color-gray-100;
  }
}

@include breakpoint("med") {
  .hero {
    border: 1px solid rgba(256, 256, 256, 0);
    background-size: cover;
    background-position: center;

    &__text {
      margin: 5rem 0rem 5rem -2rem;
      width: 66.67%;
      color: $color-black;
      padding: 2rem;
      animation: fadeAndSlideIn 0.75s ease-in-out;

      &:after {
        border-left: 2px dotted $color-gray-20;
      }
    }

    & .header-1 {
      font-family: $font-primary;
      color: $color-black;
      font-weight: 700;
    }

    & .eyebrow-1 {
      color: $color-gray-100;
    }

    &__description {
      font-size: 1.25rem;
      line-height: 1.5em;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
      color: $color-gray-100;
    }
  }
}
