@import "../../../variables/_color.scss";
@import "../../../variables/_type.scss";
@import "../../../variables/_layout.scss";

.navbar {
  position: fixed;
  top: 0;
  height: 5rem;
  padding: 0rem;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid $color-gray-10;

  & .container {
    padding: 0.5rem;
  }

  & nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .logo {
    display: none;
  }

  & .logo-glyph {
    width: 4rem;
  }

  &__mobile-controls {
    display: flex;
  }

  &__shopping-cart-toggle {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    background: transparent;
    border: none;
    opacity: 0.8;
  }

  &__mobile-toggle {
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    border: 1px solid $color-gray-20;
    border-radius: 4px;

    & svg {
      fill: $color-gray-80;
    }
  }

  &__close-button {
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
    line-height: 0rem;
    transform: translateY(-0.25rem);
    border: none;
    background-color: transparent;
  }

  &__nav-links--mobile {
    display: flex;
    flex-direction: column;
    width: 16rem;
    padding-top: 5rem;
  }

  &__nav-link {
    font-family: $font-primary;
    position: relative;
    transition: opacity 0.125s ease;
    padding: 1rem;
    border-bottom: 1px solid $color-gray-10;
    color: $color-black;
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: 0.025rem;

    &--active {
      &:before {
        position: absolute;
        content: "";
        left: 0rem;
        bottom: 0.125rem;
        width: 4px;
        height: 100%;
        background-color: $color-black;
      }
    }
  }

  &__nav-link-cart {
    border-bottom: none;
    text-align: center;
    line-height: 0rem;
    position: relative;
    opacity: 0.75;
    border-radius: 4px;
    margin-right: 0.25rem;

    &--active {
      opacity: 1;
      background-color: $color-gray-10;
    }

    &-indicator {
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      position: absolute;
      right: 0.125rem;
      top: 0.125rem;
      z-index: 10;

      &--active {
        background-color: $color-secondary-dark;
        color: #fff;
      }

      &--inactive {
        background-color: $color-gray-100;
        color: #fff;
      }

      &--empty {
        background-color: $color-gray-10;
        color: $color-gray-60;
      }

      &__value {
        font-size: 0.625rem;
        line-height: 0.95rem;
        font-weight: 500;
      }
    }
  }
}

@include breakpoint("med") {
  .navbar {
    position: fixed;
    top: 0;
    padding: 0.25rem 0rem;
    width: 100%;
    background-color: #fff;

    & nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .logo {
      display: block;
      transition: opacity 0.125s ease;
      width: 16rem;
      margin-left: -1rem;

      &:hover {
        opacity: 0.75;
      }
    }

    & .logo-glyph {
      display: none;
    }

    &__nav-links--desktop {
      display: flex;
      align-items: center;
    }

    &__nav-link {
      font-size: 1rem;
      border-bottom: none;
      padding: 0.5rem 1rem;

      &:last-child {
        margin-right: 0rem;
      }

      &:hover {
        &:before {
          position: absolute;
          content: "";
          left: 1rem;
          bottom: 0.25rem;
          width: calc(100% - 2rem);
          height: 2px;
          background-color: $color-black;
        }
      }
    }
  }
}
