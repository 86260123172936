@import "./variables/_color.scss";

* {
  font-family: "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto",
    '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"',
    '"Segoe UI Emoji"', '"Segoe UI Symbol"';
}

body {
  overflow-x: hidden;
  overscroll-behavior-y: none;
}

.form-header {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.body-2 a {
  text-decoration: underline;
  color: $color-black;
  transition: opacity 0.125s ease-in-out;

  &:hover {
    opacity: 0.75;
  }
}

.MuiDrawer-paperAnchorRight {
  transform: translateZ();
}
