@import "../../../variables/color";

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spinner {
  width: 100%;
  display: flex;
  align-items: center;
  animation: fadeIn 0.25s ease-in-out;

  &__glyph path {
    fill: $color-gray-40;
  }
  &--large {
    width: 16rem;
    margin: auto;

    & circle {
      stroke-width: 1px;
    }

    & path {
      stroke-width: 1px;
    }
  }

  &--small {
    width: 2rem;

    & circle {
      stroke-width: 2px;
    }

    & path {
      stroke-width: 2px;
    }
  }

  &__circle {
    animation-name: spin;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center;

    & circle {
      stroke: $color-gray-20;
      fill: none;
    }

    & path {
      fill: none;
      stroke: $color-gray-60;
    }
  }
}
