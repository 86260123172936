@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.menu-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__img {
    width: 70%;
    // border: 1px solid pink;
    object-fit: cover;
    height: 12rem;
    opacity: 0.675;
  }

  &__header {
    font-size: 1.25rem;
    padding-bottom: 0.5rem;
    font-family: $font-primary;
    font-weight: 500;
    color: $color-gray-100;
  }

  &__body {
    color: $color-gray-80;
    width: 80%;
  }
}

@include breakpoint("med") {
  .menu-empty-state {
    &__img {
      width: 25%;
    }

    

    &__body {
      width: 50%;
    }
  }
}
