@import "../../../variables/layout";
@import "../../../variables/type";
@import "../../../variables/color";

.testimonials {
  position: relative;
  padding-bottom: 1rem;
  overflow-x: hidden;
  height: 21rem;

  &__controls {
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;

    & button {
      height: 2rem;
      background-color: #fff;
      border: 2px solid $color-black;

      &:hover {
        border-color: $color-gray-80;
        cursor: pointer;
        & .brento-icon {
          stroke: $color-gray-80;
        }
      }

      &:active {
        background-color: $color-black;
        cursor: pointer;
        & .brento-icon {
          stroke: #fff;
        }
      }
    }

    & .brento-icon {
      margin-top: 4px;
      width: 1rem;
      stroke: $color-gray-100;
      stroke-width: 3px;
    }
  }

  &__indicators {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-family: $font-accent;
    text-align: center;
  }
}

.testimonial {
  position: absolute;
  width: calc(100% - 2rem);
  left: 1rem;
  top: 0;
  border-radius: 2px;

  // box-shadow: $color-box-shadow-2;

  &--right-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  &--right-enter-active {
    opacity: 1;
    transition: transform 500ms, opacity 500ms;
    transform: translateX(0%);
  }

  &--right-exit {
    opacity: 1;
    transform: translateX(0%);
  }

  &--right-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 500ms, opacity 500ms;
  }

  &--left-enter {
    opacity: 0;
    transform: translateX(-100%);
  }

  &--left-enter-active {
    opacity: 1;
    transition: transform 500ms, opacity 500ms;
    transform: translateX(0%);
  }

  &--left-exit {
    opacity: 1;
    transform: translateX(0%);
  }

  &--left-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 500ms, opacity 500ms;
  }

  // &:before {
  //   content: "\201C";
  //   position: absolute;
  //   top: 1rem;
  //   left: 1rem;
  //   font-size: 3rem;
  //   font-family: serif;
  //   color: $color-black;
  // }

  &__content {
    background-color: #fff;
    box-shadow: $color-box-shadow-2;
    padding: 1rem;
    width: 100%;
  }

  &__quote {
    color: $color-gray-80;
    padding-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.5em;
  }

  &__name {
    color: $color-gray-100;
    font-weight: 500;
    font-family: $font-accent;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

@include breakpoint("med") {
  .testimonials {
    padding: 1rem 4rem;

    &__controls {
      align-items: flex-start;
      padding-top: 6rem;
    }
  }

  .testimonial {
    &__content {
      width: 50%;
      margin: auto;
    }
  }
}

@include breakpoint("lrg") {
}
