@import "../../variables/layout";

.contact-content {
  display: block;

  &__title {
    padding-bottom: 2rem;
  }
}

@include breakpoint("med") {
}

@include breakpoint("lrg") {
  .contact-content {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;

    &__title {
      flex-basis: 33%;
    }

    &__main {
      flex-basis: calc(67% - 2rem);
    }
  }
}
