@import "../../variables/color";
@import "../../variables/layout";

@include breakpoint("lrg") {
  .menu-page {
    background-color: $color-gray-10;
    &__body {
      box-shadow: $color-box-shadow-2;
      padding: 0rem 2rem 1rem 2rem;
      background-color: #fff;
    }
  }
}
