@import "../../../../variables/layout";
@import "../../../../variables/color";

.quick-links {
  padding-top: 1rem;
  border-top: 1px solid $color-gray-10;

  & h2 {
    font-weight: 300;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0rem;
  }

  &__link {
    display: block;
    position: relative;
    padding: 1.5rem 1rem;
    width: 100%;
    background-color: #fff;
    box-shadow: $color-box-shadow-1;
    border-radius: 4px;
    color: $color-gray-100;
    margin-bottom: 0.5rem;

    &:after {
      content: "";
      position: absolute;
      right: 1rem;
      top: calc(50% - 4px);
      display: block;
      border-right: 2px solid $color-gray-40;
      border-top: 2px solid $color-gray-40;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
    }
  }
}

@include breakpoint("lrg") {
  .quick-links {
    &__content {
      flex-direction: row;
      padding: 2rem 0.5rem;
    }

    &__link {
      margin-right: 1rem;
      padding: 1.5rem 1rem 3rem 1rem;
      &:hover {
        background-color: $color-gray-10;
      }

      &:after {
        top: calc(1rem + 8px);
      }
    }
  }
}
