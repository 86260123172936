@import "../../../variables/color";
@import "../../../variables/layout";
@import "../../../variables/type";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.express-checkout {
  width: 100%;
  padding-top: 0.5rem;
  animation: fadeIn 0.25s ease-in-out;
  transition: all 0.25s ease;
  border-radius: 2px;

  &__vendor-button {
    border: none;
    padding: 0.75rem;
    border-radius: 2px;
    background-color: $color-black;
    box-shadow: $color-box-shadow-2;
    opacity: 0.95;
    transition: all 0.25s ease;
    margin-top: 0.5rem;

    &:hover {
      opacity: 1;
      box-shadow: $color-box-shadow-1;
      cursor: pointer;
    }

    &:disabled {
      background-color: $color-gray-10;
      box-shadow: none;
      cursor: default;

      & .express-checkout__apple-pay-logo {
        fill: $color-gray-40;
      }
    }
  }

  &__apple-pay {
    width: 100%;

    &-logo {
      width: 16%;
      fill: #fff;
    }
  }
}
