// Grayscale
$color-black: #011212;
$color-gray-100: #2c3838;
$color-gray-90: #3d4949;
$color-gray-80: #556262;
$color-gray-60: #7a8282;
$color-gray-40: #9da4a4;
$color-gray-20: #dadede;
$color-gray-10: #f2f2f2;
$color-gray-05: #fafafa;

// Primary
$color-primary: #2c3838;
$color-primary-light: #556262;
$color-primary-dark: #011212;

// Secondary
$color-secondary: #5dd9c5;
$color-secondary-light: #93fff8;
$color-secondary-lightest: #e8fcfb;
$color-secondary-dark: #18a795;
$color-secondary-darkest: #06352e;

// Status - Success
$color-success: #81c784;
$color-success-light: #b2fab4;
$color-success-dark: #519657;

// Status - Error
$color-error: #ef5350;
$color-error-light: #ff867c;
$color-error-lightest: #ffe2df;
$color-error-dark: #b61827;

// Status - Warn
$color-warn: #de7717;
$color-warn-light: #fb9434;
$color-warn-lighter: #fecea2;
$color-warn-lightest: #f8ede4;
$color-warn-dark: #6f3907;

//Box Shadow
$color-box-shadow-1: 0px 2px 8px rgba(1, 18, 18, 0.25);
$color-box-shadow-2: 0px 2px 4px rgba(1, 18, 18, 0.125);
$color-box-shadow-3: 0px 1px 4px rgba(1, 18, 18, 0.175);
$color-box-shadow-4: 2px 1px 14px rgba(1, 18, 18, 0.25);
// 0px 2px 1px rgba(1, 18, 18, 0.025);
