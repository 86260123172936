@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;700&family=Roboto+Condensed:ital,wght@0,400;0,700;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");
@import "./_layout.scss";
@import "./_color.scss";

// Font Families ----------------- /
// As variables
$font-sans: "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto",
  '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"',
  '"Segoe UI Emoji"', '"Segoe UI Symbol"', sans-serif;

$font-primary: "Inter", "-apple-system", "BlinkMacSystemFont", '"Segoe UI"',
  "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"',
  '"Segoe UI Emoji"', '"Segoe UI Symbol"', sans-serif;

$font-accent: "Barlow Condensed", sans-serif;

// As classes
.font-sans {
  font-family: $font-sans;
}

.font-primary {
  font-family: $font-primary;
}

.font-accent {
  font-family: $font-accent;
}

// Type styles ------------------- /
// As mixins
@mixin header-1 {
  font-size: 2.5rem;
  line-height: 1.2em;

  @include breakpoint("med") {
    font-size: 3.5rem;
  }

  @include breakpoint("lrg") {
    font-size: 4.5rem;
  }
}

@mixin header-2 {
  font-size: 2rem;
  line-height: 1.2em;

  @include breakpoint("med") {
    font-size: 2.5rem;
  }

  @include breakpoint("lrg") {
    font-size: 3.5rem;
  }
}

@mixin header-3 {
  font-size: 1.5rem;
  line-height: 1.2em;

  @include breakpoint("med") {
    font-size: 2rem;
  }

  @include breakpoint("lrg") {
    font-size: 2.5rem;
  }
}

@mixin header-4 {
  font-size: 1.5rem;
  line-height: 1.2em;

  @include breakpoint("med") {
    font-size: 1.5rem;
  }

  @include breakpoint("lrg") {
    font-size: 2rem;
  }
}

@mixin header-5 {
  font-size: 1.5rem;
  line-height: 1.2em;

  @include breakpoint("med") {
    font-size: 1.5rem;
  }

  @include breakpoint("lrg") {
    font-size: 1.5rem;
  }
}

@mixin body-1 {
  font-size: 1rem;
  line-height: 1.5em;

  @include breakpoint("med") {
    font-size: 1.25rem;
  }

  @include breakpoint("lrg") {
    font-size: 1.25rem;
  }
}

@mixin body-2 {
  font-size: 1rem;
  line-height: 1.5em;
}

@mixin eyebrow-1 {
  font-size: 1.25rem;
  line-height: 1em;
  margin-bottom: 1rem;
  font-weight: 500;
  font-family: $font-accent;
  letter-spacing: 2px;
  text-transform: uppercase;

  @include breakpoint("med") {
    font-size: 1.25rem;
  }

  @include breakpoint("lrg") {
    font-size: 1.5rem;
  }
}

// As classes
.header-1 {
  @include header-1;
}

.header-2 {
  @include header-2;
}

.header-3 {
  @include header-3;
}

.header-4 {
  @include header-4;
}

.header-5 {
  @include header-5;
}

.body-1 {
  @include body-1;
}

.body-2 {
  @include body-2;
}

.eyebrow-1 {
  @include eyebrow-1;
}

.eyebrow-2 {
  font-size: 1rem;
  line-height: 1em;
  margin-bottom: 1rem;
  font-weight: 400;
  font-family: $font-accent;
  letter-spacing: 2px;
  text-transform: uppercase;
}

// Font weights ------------------- /

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// As mixins
@mixin font-light {
  font-weight: $font-weight-light;
}

// As classes

.font-light {
  font-weight: $font-weight-light;
}

.font-bold {
  font-weight: $font-weight-bold;
}
