@import "../../../variables/color";

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  margin-top: 0.5rem;
  background-color: $color-gray-05;
  border: 1px solid $color-gray-20;
  border-radius: 4px;
  height: calc(100% - 1rem);

  &-empty {
    display: flex;
    flex-grow: 1;
    align-items: center;
    color: $color-gray-60;
  }

  & .MuiButton-root {
    max-width: 180px;
  }

  .image-upload-preview {
    // flex-basis: 50%;

    width: 100%;

    &__thumbnail {
      width: 100%;
      height: 12rem;
      border: 1px solid $color-gray-20;
      border-radius: 1px;
      object-fit: cover;
    }

    &__details {
      display: flex;
      margin-bottom: 2rem;
      font-size: 0.875rem;
    }

    &__field:first-child {
      flex-basis: 50%;
      min-width: 0;
      max-width: 14rem;
      margin-right: 1rem;
    }

    &__value {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $color-gray-80;
    }

    &__label {
      color: $color-gray-60;
    }
  }
}
