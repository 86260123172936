@import "../../../../../variables/color";
@import "../../../../../variables/layout";
@import "../../../../../variables/type";

.minimum-amount-modal {
  &__main {
    & .header-4,
    & .body-2 {
      padding-bottom: 1rem;
    }
  }
}

@include breakpoint("lrg") {
  .minimum-amount-modal {
    &__main {
      width: 480px;
    }
  }
}
