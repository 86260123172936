@import "../../../variables/layout";
@import "../../../variables/color";

.promo-code-manager {
  & a {
    color: $color-black;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
      color: $color-gray-80;
    }
  }
  &__header {
    padding-top: 2rem;
    border-bottom: 1px solid $color-gray-10;
  }

  &__footer {
    padding: 1rem 0rem;
    & p {
      padding-bottom: 1rem;
    }
  }
  @include breakpoint("lrg") {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & p {
        padding-bottom: 0rem;
      }
    }

    &__tabs {
      max-width: 360px;
    }
  }
}
