@import "../../../variables/layout";
@import "../../../variables/type";
@import "../../../variables/color";

.about__video-hero {
  margin-bottom: 1rem;

  & iframe {
    left: 0;
    top: 0;
    height: 12rem;
    width: 100%;
  }
}
@include breakpoint("lrg") {
  .about__video-hero {
    & iframe {
      height: 24rem;
    }
  }
}
