@import "../../../../variables/_type.scss";
@import "../../../../variables/_color.scss";

.order-list {
  & .discount-code {
    display: inline-block;
    color: $color-gray-80;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.25rem;
    background-color: $color-secondary-lightest;
    border-radius: 4px;
  }
}
