@import "../../../../variables/layout";
@import "../../../../variables/color";

.analytics-breakdown {
  &__breakdown-select {
    padding: 1rem 0rem;
    & .analytics-breakdown__breakdown-option {
      &:hover {
        cursor: pointer;
        background-color: $color-gray-20;
      }
      background-color: $color-gray-10;
      color: $color-gray-100;
      padding: 0.75rem 1.25rem;
      border: none;
      text-transform: capitalize;
      margin-right: 0.5rem;
      border-radius: 24px;
      transition: all 0.25s ease-in-out;
      &.analytics-breakdown__breakdown-option--active {
        background-color: $color-gray-90;
        color: #fff;
      }
    }
  }
}
