@import "../../../variables/type";
@import "../../../variables/color";

.apply-promo {
  padding: 0rem 0rem 1rem 0rem;
  width: 100%;
  &__primary {
    padding: 0rem;
    display: flex;
  }

  &__input {
    flex-basis: 70%;
    flex-grow: 1;

    & .MuiFormControl-fullWidth {
      // height: 3rem;
      background-color: #fff;

      & input {
        height: 1.5rem;
      }
    }
  }

  &__button {
    flex-basis: 30%;
    padding-left: 0.25rem;
  }
}

.apply-promo-details {
  width: calc(100%);
  margin-bottom: 1rem;
  border-radius: 2px;

  &__primary {
    position: relative;
    background-color: $color-gray-10;
    // border-left: 3px solid $color-secondary;
    border-radius: 0px 4px 4px 0px;
    width: 100%;
    padding: 1rem 1rem 0.25rem 0.75rem;
    margin-bottom: 0.5rem;

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 3px;
      background-color: $color-secondary;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;

    & h3 {
      color: $color-gray-100;
      font-size: 0.875rem;
      & span {
        font-weight: 700;
        color: $color-black;
      }
    }
  }

  &__contents {
    display: flex;
    align-items: baseline;
    // justify-content: space-between;
    color: $color-gray-80;
    & .body-2 {
      font-size: 0.875rem;
      padding-right: 0.25rem;
      color: $color-gray-80;
      &.min-fee {
        font-weight: 700;
      }
    }

    & ul {
      padding-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      & .apply-promo-details__benefit {
        color: $color-gray-90;
        padding: 0.5rem 1rem;
        background-color: $color-gray-05;
        margin: 0rem 0.25rem 0.5rem 0rem;
        border: 1px solid $color-gray-20;
        border-radius: 16px;
      }
    }

    & .apply-promo-details__balance {
      font-weight: 700;
      font-size: 0.875rem;
      color: $color-gray-80;
    }
  }
  & .remove-code {
    border: none;
    padding: 0;
    text-decoration: underline;
    padding: 0.5rem 0rem 1rem 0rem;
    color: $color-gray-100;
    background-color: transparent;

    &:hover {
      cursor: pointer;
      color: $color-gray-80;
    }
  }

  &__removing {
    display: flex;
    align-items: center;

    & .spinner {
      width: auto;
      margin-right: 0.5rem;
    }
  }
}

.apply-promo-min-fee {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
  color: $color-gray-100;
  padding: 0rem 0rem 0rem 1rem;
  border-radius: 0px 4px 4px 0px;
  background-color: $color-warn-lightest;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 3px;
    background-color: $color-warn;
  }

  & button {
    background-color: transparent;
    border: none;
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;

    &:hover {
      cursor: pointer;
      & svg {
        fill: $color-black;
      }
    }

    & svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: $color-warn-dark;
    }
  }
}
