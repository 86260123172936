.test-mode-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: 5000;
  background-color: #ffa500;

  &__label {
    position: absolute;
    left: 50%;
    font-size: 0.75rem;
    background-color: #ffa500;
    font-weight: 700;
    padding: 0.25rem;
  }

  &__links {
    position: absolute;
    right: 2rem;
    background-color: #ffa500;
    padding: 0.25rem;

    & a {
      color: #000;
      padding: 0rem 0.25rem;
    }
  }
}
