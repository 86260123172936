@import "../../../../variables/layout";
@import "../../../../variables/color";

.zip-code-table {
  &__filter {
    background-color: $color-gray-10;
    padding: 0.5rem;
    order: 2;
  }

  &__text-filter {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid $color-gray-20;
    position: relative;
  }

  &__results {
    height: 100%;
    max-height: 500px;
    overflow-y: scroll;
    border: 1px solid $color-gray-10;
    border-radius: 4px;
    margin-bottom: 2rem;

    & .zip-code-table__result {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      line-height: 34px;
      border-bottom: 1px solid $color-gray-10;
      transition: all 0.125s ease;

      &--default {
        color: $color-gray-80;
        &:hover {
          cursor: pointer;
          background-color: $color-gray-05;
        }
      }
      &--selected {
        color: $color-black;
        background-color: $color-gray-20;
      }
    }
  }

  &__length {
    color: $color-gray-80;
    font-size: 0.875rem;
  }
}
