@import "../../../variables/layout";
@import "../../../variables/type";
@import "../../../variables/color";

.contact-field {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: $color-black;
  padding: 1rem;
  box-shadow: $color-box-shadow-1;

  & .MuiSvgIcon-root {
    margin-right: 1rem;
  }
}

// @include breakpoint("med") {
//   .primary-contacts {
//     display: flex;
//     justify-content: space-between;

//     & .contact-field {
//       flex-basis: calc(50% - 0.5rem);
//       display: flex;
//       flex-direction: column;

//       & .MuiSvgIcon-root {
//         margin-bottom: 1rem;
//       }
//     }
//   }
// }
