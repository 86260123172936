@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

@keyframes tySlideUp {
  from {
    transform: translateY(4rem);
    opacity: 0;
  }
  to {
    transform: translateY(0rem);
    opacity: 1;
  }
}

@keyframes tySlideDown {
  from {
    transform: translateY(-4rem);
    opacity: 0;
  }
  to {
    transform: translateY(0rem);
    opacity: 1;
  }
}

@keyframes tyFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.thank-you {
  color: $color-black;
  text-align: center;
  background-color: $color-gray-10;
  margin: -1rem;
  padding: 1rem;

  .anim-check {
    animation: tySlideDown 0.5s ease-in-out;
  }

  &__header {
    margin-bottom: 2rem;
    animation: tySlideUp 0.5s 0.25s ease-in-out backwards;

    .body-2 {
      animation: tyFadeIn 0.5s 2s ease-in-out backwards;
    }

    & h1 {
      margin-bottom: 1rem;
    }

    & p {
      color: $color-gray-80;
    }

    & .thank-you__order-number {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      color: $color-gray-100;
      animation: tyFadeIn 0.5s 1s ease-in-out backwards;

      & .thank-you__label {
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
        color: $color-gray-100;
      }

      & .thank-you__value {
        font-size: 1rem;
        font-weight: 700;
        color: $color-gray-100;
      }
    }
  }

  &__line-items {
    padding-bottom: 3rem;
  }

  &__line-item {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray-10;
    margin: 0rem -1rem;
    padding: 1rem;

    &-quantity {
      background-color: $color-gray-10;
      color: $color-gray-80;
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
      border-radius: 1rem;
    }
  }

  &__order-details {
    background-color: #fff;
    box-shadow: $color-box-shadow-2;
    margin: 0rem -0.5rem;
    padding: 1rem;
    text-align: left;
    animation: tyFadeIn 0.5s 2.125s ease-in-out backwards;

    & h2 {
      color: $color-gray-80;
      font-size: 1rem;
      margin-bottom: 2rem;
    }

    &-group {
      margin-bottom: 2rem;
      display: flex;

      & h3 {
        color: $color-gray-100;
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        margin-right: 2rem;
        flex-basis: 40%;
      }
      & p {
        margin-bottom: 0.5rem;
        color: $color-gray-80;
        font-size: 0.875rem;
        text-align: left;
        flex-basis: calc(60% - 1rem);
      }
    }
  }

  &__label {
    color: $color-gray-80;
    margin-bottom: 1rem;
  }
}

@include breakpoint("lrg") {
  .thank-you {
    margin: 0rem;
    padding-bottom: 3rem;
    background-color: #fff;

    & .thank-you__order-number {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      color: $color-gray-100;

      & .thank-you__label {
        font-size: 1.125rem;
      }

      & .thank-you__value {
        font-size: 1.5rem;
        font-weight: 700;
        color: $color-gray-100;
      }
    }

    &__header {
      width: 67%;
      margin: 0rem auto;
      padding-bottom: 2rem;
    }

    &__order-details {
      width: 67%;
      margin: auto;
      background-color: $color-gray-05;
    }
  }
}
