@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeAndSlide {
  from {
    opacity: 0;
    transform: translateX(16rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
}

.menu-hero .countdown {
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem;
  animation: fadeIn 0.5s ease;
  animation-delay: 0.5s;

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;

    &:after {
      position: absolute;
      right: -0.75rem;
    }

    &:last-child {
      margin-right: 0rem;
    }

    &:last-child:after {
      content: "";
      display: none;
    }
  }

  &__value {
    display: block;
    font-weight: 700;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  &__label {
    font-family: $font-accent;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    color: $color-gray-80;
  }
}
@include breakpoint("med") {
  .menu-hero .countdown {
    justify-content: flex-end;
    animation: fadeAndSlide 0.5s ease;

    &__field {
      margin-right: 2rem;
    }
    &__field:last-child {
      margin-right: 0rem;
    }
  }
}
