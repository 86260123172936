@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.gift-card-item {
  position: relative;
  &__container {
    height: 10rem;
    background-color: $color-gray-10;
    width: 100%;
    border-radius: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
      transition: opacity 0.25s ease;
    }
  }
  & p {
    color: $color-black;
    line-height: 1.25em;
    font-weight: 700;
    padding-top: 2px;
  }

  & .eyebrow-1 {
    display: none;
  }
}

@include breakpoint("lrg") {
  .gift-card-item {
    &__container {
      height: 16rem;
    }

    & .gift-card-item__text-wrapper {
      font-size: 1.25rem;
      line-height: 1.5em;
      padding: 0.5rem 0rem;

      & p {
        padding-top: 0px;
      }
    }

    &:hover {
      & img {
        opacity: 0.75;
      }

      & .gift-card-item__text-wrapper {
        text-decoration: underline;
        text-decoration-color: $color-black;
      }
    }
  }
}
//   .gift-card-item {
//     grid-column-start: 1;
//     grid-column-end: 4;

//     &:hover {
//       & .gift-card-item__text-wrapper {
//         padding-left: 4.5rem;
//         opacity: 0.9;
//       }

//       & .eyebrow-1 {
//         &:after {
//           width: 17%;
//         }
//       }
//     }
//     &__container {
//       height: 16rem;
//     }

//     &__text-wrapper {
//       display: flex;
//       flex-direction: column;
//       position: absolute;
//       z-index: 60;
//       left: 0px;
//       top: 0px;
//       height: 100%;
//       padding: 4rem;
//       width: 75%;
//       border-radius: 4px 0px 0px 4px;
//       background-image: linear-gradient(
//         90deg,
//         rgba(0, 0, 0, 0.8) 0%,
//         rgba(0, 0, 0, 0.7) 60%,
//         rgba(0, 0, 0, 0) 100%
//       );
//       transition: padding-left 0.25s ease-in-out, opacity 0.25s ease-in-out;

//       & .title {
//         display: inline-block;
//         height: 100%;
//         padding-bottom: 1rem;
//         font-size: 2.5rem;
//         width: 70%;
//         z-index: 100;
//         color: #fff;
//       }

//       & .eyebrow-1 {
//         display: block;
//         position: relative;
//         color: #fff;
//         font-weight: 400;

//         &:after {
//           content: "";
//           position: absolute;
//           left: 0px;
//           bottom: -2px;
//           height: 1px;
//           width: 0%;
//           background-color: #fff;
//           transition: all 0.125s ease;
//         }
//       }
//     }
//   }
// }
