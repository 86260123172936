@import "../../../variables/layout";
@import "../../../variables/color";

.gift-card-manager {
  &__header {
    padding-top: 2rem;
    border-bottom: 1px solid $color-gray-10;
  }
  @include breakpoint("lrg") {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__controls {
    }
    &__tabs {
      max-width: 360px;
    }
  }
}
