@import "../../../variables/type";
@import "../../../variables/color";
@import "../../../variables/layout";

.brento-tab {
  &.MuiButtonBase-root {
    color: $color-gray-80;
    border: 1px solid $color-gray-20;
    text-transform: none;
    flex-grow: 1;

    &:hover {
      background-color: $color-gray-05;
    }

    &.Mui-selected {
      background-color: $color-gray-10;
    }
  }
}
