@import "../../../../variables/layout";
@import "../../../../variables/color";

.gift-card-details-hero {
  padding: 1rem 0rem;
  border-bottom: 3px solid $color-gray-20;

  .eyebrow-1 {
    color: $color-gray-80;
  }
}

@include breakpoint("med") {
  .gift-card-details-hero {
    padding: 2rem 0rem;
  }
}
