@import "../../../variables/layout";
@import "../../../variables/color";

.create-gift-card-form {
  width: 100%;

  &__field-group {
    width: 100%;
    padding: 1rem 0rem;
  }

  &__custom-name-field {
    padding: 1rem 0rem;
  }

  & .error {
    color: $color-error;
    padding: 1rem 0rem;
  }

  & .character-count {
    font-size: 0.75rem;
    color: $color-gray-80;
    padding: 0.5rem 0rem;
  }

  & .alert-message {
    margin-bottom: 1rem;
  }
}
