@import "../../../variables/layout";
@import "../../../variables/type";
@import "../../../variables/color";

.purchase-gift-card {
  padding: 1rem 0rem;

  &__disclaimer {
    border-top: 1px solid $color-gray-10;
    color: $color-gray-80;
    padding: 1rem 0rem 3rem 0rem;
    font-size: 0.75rem;
    line-height: 1.5em;

    & a {
      color: $color-gray-100;
      text-decoration: underline;
    }
  }

  & .header-2 {
    color: $color-black;
  }

  & .body-1 {
    color: $color-gray-80;
  }
}
@include breakpoint("lrg") {
  .purchase-gift-card {
    &__primary-content {
      width: 67%;
    }
  }
}
