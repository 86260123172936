@import "../../../../variables/layout";
@import "../../../../variables/color";

.analytics-stats {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__stat {
    padding: 1rem;
    margin-bottom: 1rem;
    flex-basis: calc(50% - 0.5rem);
    box-shadow: $color-box-shadow-3;
    border-radius: 4px;
  }
  &__value {
    font-size: 1.25rem;
    line-height: 1.2em;
    font-weight: 700;
    color: $color-black;
    padding-bottom: 0.25rem;
  }
  &__label {
    font-size: 0.875rem;
    font-weight: 400;
    color: $color-gray-80;
  }
}

@include breakpoint("lrg") {
  .analytics-stats {
    flex-wrap: nowrap;
    padding-left: 0.125rem;
    &__stat {
      flex-basis: calc(25% - 0.5rem);
      padding: 1.25rem 1rem 2rem 1rem;
    }
    &__value {
      font-size: 1.75rem;
    }
    &__label {
      font-size: 1rem;
      line-height: 1.2em;
    }
  }
}
