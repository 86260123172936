@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.anim-check {
  width: 96px;
  circle {
    fill: #5dd9c5;
    stroke: #18a795;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  polyline {
    fill: none;
    stroke: #ffffff;
    stroke-width: 3;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s 0.25s ease forwards;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
}
