@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.menu-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 0rem;

  &--unloaded {
    visibility: hidden;
    height: 0px;
    overflow: hidden;
  }

  &--loaded {
    visibility: visible;
    height: default;
    overflow: default;
  }

  &__contents {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__title {
    flex-basis: 80%;
  }

  &__name {
    font-size: 1rem;
    line-height: 1.25em;
    margin-bottom: 0.25em;
    font-family: $font-primary;
    font-weight: 700;
    color: $color-black;
  }

  &__subtitle {
    font-weight: 500;
    color: $color-gray-80;
    font-size: 0.875rem;
  }

  &__price {
    color: $color-gray-60;
    font-size: 0.875rem;
  }

  &__image-container {
    position: relative;
    z-index: 1;
  }

  &__new {
    position: absolute;
    background-color: $color-black;
    z-index: 2;
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    left: -0.25rem;
    top: -0.25rem;
    color: #fff;
    letter-spacing: 4px;
  }

  &__image {
    width: 100%;
    height: 10rem;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 4px;
  }

  &__dietary-tags {
    display: flex;
  }
}
@include breakpoint("lrg") {
  .menu-item {
    position: relative;
    margin-bottom: 1rem;

    &__name {
      font-size: 1.25rem;
    }

    &__image {
      height: 16rem;
      margin-bottom: 0.5rem;
      transition: opacity 0.125s ease;
    }

    &__new {
      padding: 0.5rem 1rem;
    }

    &__price {
      font-size: 1rem;
    }
  }

  .menu-item--hover {
    & .menu-item__name,
    & .menu-item__subtitle {
      text-decoration: underline;
    }

    & .menu-item__image {
      opacity: 0.75;
    }

    & .menu-item__new {
      background-color: $color-gray-100;
    }

    & .menu-item__price {
      color: $color-gray-100;
    }
  }
}
