@import "../../variables/type";
@import "../../variables/color";

.cart-header {
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $color-gray-20;

  &__core {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  & .header-3 {
    margin-bottom: 1.5rem;
  }

  &__line-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    flex-basis: 100%;
    width: 100%;

    &--error {
      & .cart-header__line-item-label {
        color: $color-error-dark;
      }

      & .cart-header__line-item-value {
        color: $color-error-dark;
      }
    }

    &--addon {
      .cart-header__line-item-value {
        color: $color-secondary-dark;
      }
    }

    &--warn {
      .cart-header__line-item-value {
        color: $color-warn;
      }
    }

    &-label {
      color: $color-gray-80;
    }

    // &-divider {
    //   flex-grow: 1;
    //   border-bottom: 2px dotted $color-gray-10;
    //   line-height: 0rem;
    //   height: 1px;
    //   margin: 0rem 0.5rem;
    // }

    & .display-price {
      color: $color-black;
      font-weight: 700;
      font-size: 1.5rem;

      &__dollar-sign {
        font-weight: 300;
      }

      &__cents {
        font-weight: 700;
        font-size: 0.75em;
        line-height: 1.25em;
        vertical-align: top;
      }
    }

    &-value {
      font-weight: 500;
      color: $color-gray-60;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    flex-basis: 100%;
    width: 100%;

    &-label {
      color: $color-black;
      font-weight: 700;

      &--discount {
        font-size: 0.875em;
      }
    }

    &-divider {
      flex-grow: 1;
      border-bottom: 2px dotted $color-gray-10;
      line-height: 0rem;
      height: 1px;
      margin: 0rem 0.5rem;
    }

    & .display-price {
      color: $color-black;
      font-weight: 700;
      font-size: 1.25rem;

      &__dollar-sign {
        font-weight: 300;
      }

      &__cents {
        font-weight: 700;
        font-size: 0.75em;
        line-height: 1.25em;
        vertical-align: top;
      }
    }

    &-value {
      font-weight: 500;
      color: $color-gray-60;
    }
  }

  &__total--discount {
    font-size: 0.875rem;
    color: $color-gray-80;
    margin-bottom: 1rem;

    & .cart-header__total-label {
      color: $color-gray-80;
    }

    & .display-price {
      font-size: 1em;
      text-decoration: line-through;
      color: $color-gray-80;
    }

    & .cart-header__total-divider {
      display: none;
    }
  }

  & .MuiButton-outlinedSizeSmall {
    margin-top: 0.5rem;
  }

  & .alert-message {
    margin-top: 1rem;

    &__content p {
      color: $color-gray-100;
      font-size: 0.875rem;
      line-height: 1.5em;
      margin-bottom: 0.5rem;
    }

    &__content a {
      color: $color-gray-100;
      text-decoration: underline;
    }
  }
}

@include breakpoint("lrg") {
  .cart-header {
    border: 1px solid $color-gray-20;
    background-color: $color-gray-05;
    padding: 1rem;
    border-radius: 4px;

    &__line-item {
      margin-bottom: 1rem;
    }
  }
}
