@import "../../../variables/layout";
@import "../../../variables/type";

.why-brento-box {
  display: flex;
  flex-direction: column;
  &__image {
    & img {
      width: 100%;
    }
  }

  &__content {
    background-color: #fff;
  }
}

@include breakpoint("med") {
  .why-brento-box {
    flex-direction: row;

    &__image {
      flex-basis: 50%;

      & img {
        width: 100%;
      }
    }

    &__content {
      flex-basis: 50%;
      padding: 2rem;
      margin: 4rem 0rem 4rem -2rem;
    }
  }
}

@include breakpoint("lrg") {
}
