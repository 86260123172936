@import "../../../variables/_layout.scss";
@import "../../../variables/_type.scss";
@import "../../../variables/_color.scss";

.promo-code-details {
  &__email-list {
    &-title {
      font-size: 1.25rem;
      padding-bottom: 1rem;
    }
    &-table {
      border: 1px solid $color-gray-10;
      border-radius: 4px;

      &-header {
        display: flex;
        background-color: $color-gray-10;
        justify-content: space-between;
        padding: 0.5rem;
        border-radius: 4px 4px 0px 0px;
        & p {
          font-weight: 500;
          color: $color-gray-90;
        }
      }
      &-row {
        display: flex;
        padding: 1rem 0.5rem;
        justify-content: space-between;
        & p {
          font-size: 0.875rem;
        }
      }
    }
  }
}

@include breakpoint("lrg") {
  .promo-code-details {
    &__email-list {
      &-table {
        &-header,
        &-row {
          & p {
            flex-basis: 33%;
          }
        }
      }
    }
  }
}
