@import "../../variables/type";
@import "../../variables/color";

.meal-ingredients {
  &__label {
    border-bottom: 2px dotted $color-gray-20;
    margin-bottom: 1rem;
  }
  & .eyebrow-2 {
    font-weight: 500;
    line-height: 2em;
    color: $color-gray-80;
  }

  &__ingredients {
    color: $color-gray-60;
    margin-bottom: 2rem;
  }
}
