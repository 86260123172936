@import "../../variables/type";
@import "../../variables/color";

.cart-item {
  position: relative;
  display: flex;
  border-bottom: 1px solid $color-gray-10;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  align-items: flex-start;
  justify-content: space-between;

  & a {
    max-height: 96px;
  }

  &__img {
    flex-basis: 10%;
    width: 6rem;
    height: 6rem;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid $color-gray-10;
  }

  &__details {
    margin-left: 0.5rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
  }

  &__title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25em;
    width: 75%;

    & a {
      color: $color-black;
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
  }

  & .display-price {
    position: absolute;
    right: 0.5rem;
    top: 0.25rem;
    line-height: 1em;
    color: $color-gray-80;
    font-weight: 500;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;

    &__dollar-sign {
      font-weight: 300;
    }
  }

  &__remove-btn {
    max-height: 2.5rem;
    max-width: 2.5rem;
    border-radius: 2px;

    &.MuiIconButton-root {
      border: 1px solid $color-gray-10;
      border-radius: 4px;
    }
  }
}

@include breakpoint("med") {
  .cart-item {
    align-items: center;
    margin-bottom: 0rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    &__img {
      width: 6rem;
      height: 6rem;
    }

    &__details {
      flex-direction: row;
      align-items: center;
      padding: 0.5rem 0rem;
      margin-left: 1rem;
    }

    &__title {
      flex-basis: 33%;
    }

    & .display-price {
      position: relative;
    }

    &__controls {
      flex-basis: 35%;
    }
  }
}

@include breakpoint("lrg") {
  .cart-item {
    &__title {
      flex-basis: 40%;
    }
  }
}
