@import "../../../../variables/layout";
@import "../../../../variables/color";

.promo-code-details-table {
  padding: 2rem 0rem;

  &__row {
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem;
    border-bottom: 1px solid $color-gray-20;
  }

  &__key {
    font-weight: 700;
    padding-bottom: 0.5rem;
    flex-basis: 50%;
  }

  &__benefits-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__benefit {
    padding: 0.5rem 1rem;
    background-color: $color-gray-10;
    border-radius: 16px;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
  }
}

@include breakpoint("med") {
  .promo-code-details-table {
    &__row {
      flex-direction: row;
    }

    &__key {
      padding-bottom: 0rem;
      flex-basis: 33%;
    }
  }
}
