@import "../../variables/layout";
@import "../../variables/type";
@import "../../variables/color";

.meal-macros {
  display: flex;
  justify-content: space-between;
  background-color: $color-gray-10;
  padding: 1rem;
  margin-bottom: 1rem;
  clear: both;

  &__value {
    font-size: 1.125rem;
    font-weight: 700;
    color: $color-gray-100;
    margin-bottom: 0.25rem;
  }

  &__label {
    font-family: $font-accent;
    color: $color-gray-80;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.875rem;
  }
}

@include breakpoint("lrg") {
  .meal-macros {
    margin-bottom: 1rem;

    &__value {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }

    &__label {
      font-size: 1rem;
    }
  }
}
