@import "../../../variables/type";
@import "../../../variables/color";
@import "../../../variables/layout";

.menu-hero {
  &__contents {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: #fff;
    border-radius: 2px 2px 0px 0px;
    // border-bottom: 2px dotted $color-gray-20;
  }

  &__title {
    position: relative;
    color: $color-black;
    @include header-2;
  }

  &__sub-section {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    font-family: $font-accent;
    font-size: 1.25rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

@include breakpoint("med") {
  .menu-hero {
    background-image: url(../../../img/menu-hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 4rem 0rem 0rem 0rem;

    &__contents {
      margin: 4rem 0rem 0rem 0rem;
      padding: 2rem;
      background-color: #fff;
      overflow-x: hidden;
    }
  }
}
