@import "../../../variables/layout";
@import "../../../variables/type";
@import "../../../variables/color";

.footer {
  position: relative;
  background-color: $color-black;
  padding: 2rem 0rem;
  color: $color-gray-05;

  &:before {
    content: "";
    position: absolute;
    height: 0.5rem;
    top: 0rem;
    left: 0rem;
    width: 100%;
    border-bottom: 3px dotted $color-gray-100;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo-container {
    width: 75%;
  }

  &__links {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  &__site-links {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }

  &__nav-link {
    color: $color-gray-05;
    font-family: $font-primary;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  &__legal {
    margin-top: 3rem;
    text-align: center;
    color: $color-gray-60;
    font-size: 0.75rem;

    a {
      color: $color-gray-40;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include breakpoint("lrg") {
  .footer {
    &__content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;
    }

    &__logo-container {
      flex-basis: 25%;
    }

    &__links {
      width: 33%;
    }

    &__nav-link {
      font-size: 1.25rem;
    }

    &__legal {
      text-align: left;
    }
  }
}
