@import "../../../variables/_color";
@import "../../../variables/_layout";

.admin-navbar {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: 2;
  background-color: $color-gray-100;
  border-bottom: 1px solid #cacaca;
  box-shadow: $color-box-shadow-2;

  @include breakpoint("lrg") {
    position: fixed;
    left: 0;
    top: 0;
    flex-direction: column;
    max-width: 14rem;
    min-height: 100vh;
    border-right: 1px solid $color-black;
    border-bottom: none;
    box-shadow: $color-box-shadow-4;
  }

  &__link {
    position: relative;
    text-align: center;
    padding: 1rem 0.75rem;
    text-decoration: none;
    font-weight: 400;
    color: $color-gray-05;

    &:hover {
      background-color: $color-gray-90;
    }

    @include breakpoint("lrg") {
      padding: 1.5rem;
      border-bottom: 1px solid $color-black;
      text-align: left;
      flex-basis: auto;
    }
  }

  &__link.active {
    color: #fff;
    background-color: $color-gray-80;
    font-weight: 600;

    &::before {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $color-secondary;
    }

    @include breakpoint("lrg") {
      &::before {
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: $color-secondary;
      }
    }
  }
}
