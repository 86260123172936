@import "../../../../variables/_layout.scss";
@import "../../../../variables/_type.scss";
@import "../../../../variables/_color.scss";

.meal-breakdown {
  border: 1px solid $color-gray-20;
  border-radius: 4px;
  padding-top: 0.5rem;
  height: 450px;
  overflow-y: scroll;

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    border-bottom: 1px solid $color-gray-10;

    & img {
      width: 4rem;
      object-fit: contain;
      margin-right: 1rem;
      border-radius: 4px;
    }

    &-content {
      display: flex;
      flex-grow: 1;
      padding-right: 1rem;
      justify-content: space-between;
    }

    &-name {
      color: $color-gray-100;
    }

    &-qty {
      font-weight: 700;
      $color: $color-black;
    }
  }
}

@include breakpoint("lrg") {
  .meal-breakdown {
    max-height: 450px;
    overflow-y: scroll;

    &__item {
      &-content {
        flex-grow: 0;
        width: 67%;
      }
    }
  }
}
