@import "../../../variables/type";
@import "../../../variables/color";
@import "../../../variables/layout";

.menu-view-select {
  padding-bottom: 1rem;
}

@include breakpoint("lrg") {
  .menu-view-select {
    width: 33%;
  }
}
