@import "../../../../variables/layout";
@import "../../../../variables/color";

.promo-code-details-hero {
  display: flex;
  flex-direction: column;
}

@include breakpoint("med") {
  .promo-code-details-hero {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
