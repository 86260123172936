@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.checkout-summary {
  background-color: $color-gray-05;
  margin: 1rem -1rem;
  padding: 1rem;

  &__order-details {
    padding-bottom: 1rem;
  }

  &__field {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
    font-size: 0.875rem;
  }

  &__label {
    color: $color-gray-80;
    white-space: nowrap;
    overflow-x: ellipses;
  }

  &__value {
    color: $color-gray-80;

    &.checkout-summary__value--addon {
      color: $color-secondary-dark;
    }
  }

  &__mobile-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-label {
      color: $color-gray-100;
      font-weight: 500;
      margin-right: 0.5rem;
    }

    &-divider {
      flex-basis: calc(100% - 4rem);
      height: 1px;
      border-bottom: 2px dotted $color-gray-20;
      margin-right: 1rem;
    }

    &-value {
      font-weight: 700;
      color: $color-gray-100;
      font-size: 1.25rem;
    }
  }

  &__review-toggle {
    position: relative;
    border: 1px solid $color-gray-20;
    border-radius: 1rem;
    padding: 0.5rem 2rem 0.5rem 1rem;

    &:after {
      position: absolute;
      right: 0rem;
      top: 0rem;
      line-height: auto;
      height: 2rem;
      width: 2rem;
      font-size: 1.5rem;
      font-weight: 300;
    }

    &--open {
      background-color: $color-gray-10;
      color: $color-gray-100;
      &:after {
        content: "–";
      }
    }

    &--closed {
      background-color: transparent;
      color: $color-gray-80;
      &:after {
        content: "+";
        color: $color-gray-60;
      }
    }
  }
}

@include breakpoint("lrg") {
  .checkout-summary {
    background-color: $color-gray-05;
    border: 1px solid $color-gray-20;
    padding: 1rem;
    margin: 0rem;
  }
}
