@import "../../variables/_color";
@import "../../variables/_type";

@keyframes alertMobileTransition {
  from {
    opacity: 0;
    top: -4rem;
  }
  to {
    opacity: 1;
    top: 5rem;
  }
}

@keyframes alertDesktopTransition {
  from {
    opacity: 0;
    top: -2rem;
  }
  to {
    opacity: 1;
    top: 1rem;
  }
}

.alert {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 5rem;
  left: 0.5rem;
  width: calc(100% - 2rem);
  max-width: calc(100vw - 1rem);
  background-color: #fff;
  padding: 1rem;
  z-index: 3;
  box-shadow: $color-box-shadow-1;
  box-sizing: border-box;
  animation: alertMobileTransition 0.25s ease-in-out;

  &__name {
    font-weight: $font-weight-bold;
    color: $color-black;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }

  &__message {
    color: $color-gray-80;
    font-size: 0.875rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    height: 100%;
    width: 4px;
  }

  &--success {
    border: 1px solid $color-success;

    &::before {
      background-color: $color-success;
    }
  }

  @include breakpoint("lrg") {
    & {
      top: 1rem;
      max-width: 480px;
      animation: alertDesktopTransition 0.25s ease-in-out;
    }

    .admin-layout__primary & {
      z-index: 4;
      right: 0rem;
      left: auto;
    }
  }
}
