@import "../../../../../variables/color";
@import "../../../../../variables/layout";
@import "../../../../../variables/type";

.confirm-remove-cart {
  text-align: center;

  &__img {
    width: 8rem;
    height: 8rem;
    margin-bottom: 1rem;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: $color-box-shadow-1;
  }

  &__header {
    font-weight: 400;
    font-size: 1rem;
    font-family: $font-primary;
    margin-bottom: 2rem;
    color: $color-gray-80;
  }

  &__meal-name {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5em;
    display: block;
    color: $color-black;
  }
}
