@import "../../variables/layout";
@import "../../variables/type";
@import "../../variables/color";

.gift-card-page {
  padding-top: 1rem;

  &__content {
    padding-bottom: 2rem;
  }

  &__img-container {
    & img {
      width: 100%;
      height: 12rem;
      object-fit: cover;
      border-radius: 8px;
      margin: 1rem 0rem;
    }
  }

  &__primary-content {
    & .header-2 {
      color: $color-black;
      padding-bottom: 0.5rem;
    }

    & .body-1 {
      color: $color-gray-100;
    }
  }

  & &__learn-more {
    color: $color-gray-80;
    padding: 2rem 0rem;

    & p {
      font-size: 0.875rem;
      line-height: 1.5em;
    }

    & a {
      padding: 0.5rem 0rem;
      display: inline-block;
      text-decoration: underline;
      color: $color-black;

      &:hover {
        color: $color-gray-80;
      }
    }
  }

  & .header-4 {
    font-weight: 300;
    padding-bottom: 1rem;
    color: $color-gray-80;
  }

  & #gift-card-faqs {
    padding-top: 4rem;
  }
}

@include breakpoint("lrg") {
  .gift-card-page {
    &__content {
      display: flex;
      justify-content: space-between;
      padding-bottom: 4rem;
    }

    &__img-container {
      flex-basis: 35%;
      & img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 8px;
        margin: 1rem 0rem;
      }
    }

    &__primary-content {
      flex-basis: 60%;
    }

    & &__learn-more {
      & p {
        font-size: 1rem;
      }
    }
  }
}
