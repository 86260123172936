@import "../../../variables/_type.scss";
@import "../../../variables/_layout.scss";
@import "../../../variables/_color.scss";

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.meal-manager {
  &-header {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    &__title {
      @include header-4;
      color: $color-black;
      margin-bottom: 0.5rem;
    }

    &__label {
      margin-bottom: 1rem;
      color: $color-gray-80;
    }

    @include breakpoint("lrg") {
      margin-top: 2rem;
      justify-content: space-between;
      align-items: baseline;
    }
  }
}

.meal-manager__main {
  display: flex;
  flex-direction: column;
}

.meal-manager__filter {
  background-color: $color-gray-10;
  padding: 0.5rem;
  order: 2;
}

.meal-manager__text-filter {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid $color-gray-20;
  position: relative;
}

.meal-manager__selection-panel {
  overflow-y: scroll;
  border: 1px solid $color-gray-10;
  order: 3;

  & .ordered-average {
    display: flex;
    & .MuiTypography-root {
      font-size: 0.75rem;
      display: inline-block;
      padding: 0.25rem 0.5rem;
      border-radius: 12px;
      background-color: $color-gray-10;
    }
  }
}

.meal-manager__selection-panel--default {
  max-height: 51vh;
}

.meal-manager__selection-panel--shrink {
  max-height: 40vh;
}

.meal-manager__selection-form {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.selection-panel__btn {
  text-align: left;
  padding: 1rem;
  font-size: 0.875rem;
  border: none;
  border-bottom: 1px solid $color-gray-20;

  &:last-child {
    border-bottom: none;
  }
}

.selection-panel__btn:hover {
  cursor: pointer;
}

.selection-panel__btn--active {
  background-color: $color-gray-10;
}

.selection-panel__btn--inactive {
  background-color: #fff;
}

.meal-manager__toolbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid $color-gray-20;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  background-color: #fff;

  & .meal-count-value {
    font-weight: $font-weight-bold;
  }
}

@include breakpoint("lrg") {
  .meal-manager__main {
    flex-direction: column;
  }

  .meal-manager__selection-panel--default,
  .meal-manager__selection-panel--shrink {
    max-height: auto;
  }

  .meal-manager__selection-panel {
    flex-basis: 100%;
    max-height: calc(100vh - 18.5rem);
    overflow-y: scroll;
  }

  .meal-manager__filter {
    border-radius: 4px 4px 0px 0px;
  }

  .meal-manager__text-filter {
    padding: 0.75rem 0.5rem;
  }

  .meal-manager__toolbar {
    position: relative;
    margin: 0rem;
    padding: 1rem 0rem 1rem 1rem;
    width: 100%;
    order: 3;

    & .meal-count-value {
      font-weight: $font-weight-bold;
    }
  }

  .selection-panel__btn {
    font-size: 1rem;
  }

  .selection-panel__btn--active:hover {
    background-color: $color-gray-20;
  }

  .selection-panel__btn--inactive:hover {
    background-color: $color-gray-05;
  }

  .meal-manager__selection-panel:hover {
    cursor: pointer;
  }

  .action-panel__controls {
    flex-direction: column;

    & button:first-child {
      margin-bottom: 0.5rem;
    }
  }

  .meal-manager__empty-state {
    border-top: none;
    padding: 2rem;
    margin: 0rem;

    &__header {
      font-weight: $font-weight-bold;
      color: $color-gray-80;
      margin-bottom: 0.5rem;
    }
  }
}
