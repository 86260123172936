@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.checkout {
  &__disclaimer {
    border-top: 1px solid $color-gray-10;
    color: $color-gray-80;
    padding: 1rem 0rem 3rem 0rem;
    font-size: 0.75rem;
    line-height: 1.5em;

    & p {
      text-align: center;
    }

    & a {
      color: $color-gray-100;
      text-decoration: underline;
    }
  }
}

@include breakpoint("lrg") {
  .checkout {
    & .header-2 {
      padding-top: 2rem;
    }

    &__header {
      border-bottom: 1px solid $color-gray-10;
    }

    &__main {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__disclaimer {
      border-top: none;

      & p {
        text-align: left;
      }
    }

    & .checkout-form {
      flex-basis: calc(67% - 3rem);
      order: 1;
    }

    & .checkout-summary {
      order: 2;
      flex-basis: 33%;
    }
  }
}
