@import "../../variables/_color.scss";
@import "../../variables/_type.scss";
@import "../../variables/layout";

.supporting-hero {
  padding: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  & .header-1 {
    color: $color-gray-05;
    font-family: $font-primary;
  }
}
@include breakpoint("med") {
  .supporting-hero {
    padding: 2rem;
  }
}
