@import "../../../variables/type";
@import "../../../variables/color";
@import "../../../variables/layout";

.brento-stepper {
  display: flex;

  // .MuiButton-root {
  //   min-width: 2rem;
  //   width: 3rem;
  //   height: 3rem;
  //   padding: 0rem;
  //   color: blue;
  // }
  &__button.MuiButton-root {
    color: $color-gray-100;
    background-color: $color-gray-10;
    border-color: $color-gray-20;
    touch-action: manipulation;

    &:disabled {
      background-color: transparent;
    }

    &:focus,
    a:active,
    &:hover {
      background-color: $color-gray-20;
    }

    &:hover {
      border-color: $color-gray-40;
    }
  }
  &__button.brento-stepper__decrement.MuiButton-root {
    border-radius: 0.25rem 0rem 0rem 0.25rem;
  }
  &__button.brento-stepper__increment.MuiButton-root {
    border-radius: 0rem 0.25rem 0.25rem 0rem;
  }

  &__input.MuiInputBase-root {
    text-align: center;
    border: 1px solid $color-gray-20;
    border-width: 1px 0px 1px 0px;

    & .MuiInputBase-input {
      text-align: center;
    }
  }
}

.brento-stepper.brento-stepper--lrg {
  .brento-stepper__button.MuiButton-root {
    min-width: 2.5rem;
    width: 3rem;
    height: 3rem;
  }

  .brento-stepper__input.MuiInputBase-root {
    max-width: 3rem;
    height: 3rem;
  }
}

.brento-stepper.brento-stepper--sml {
  .brento-stepper__button.MuiButton-root {
    min-width: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .brento-stepper__input.MuiInputBase-root {
    max-width: 2.5rem;
    height: 2.5rem;

    &.Mui-disabled input {
      color: $color-black;
      -webkit-text-fill-color: $color-black;
    }
  }
}

@include breakpoint("lrg") {
  .brento-stepper.brento-stepper--lrg {
    .brento-stepper__button.MuiButton-root {
      min-width: 2.5rem;
      width: 3rem;
      height: 3rem;
    }

    .brento-stepper__input.MuiInputBase-root {
      max-width: 4rem;
      height: 3rem;
    }
  }
}
