@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.gift-card-thank-you {
  color: $color-gray-100;
  display: flex;
  align-items: center;

  &__content {
    background-color: #fff;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__discount,
  &__details {
    border-radius: 8px;
    box-shadow: $color-box-shadow-3;

    & h2 {
      color: $color-gray-80;
      text-align: center;
      background-color: $color-gray-10;
      border-radius: 8px 8px 0px 0px;
      margin-bottom: 0rem;
      padding: 0.5rem 0rem;
    }

    & h3 {
      padding-bottom: 1rem;
    }

    & .detail-field {
      padding-bottom: 1rem;

      & .label {
        padding-bottom: 0.5rem;
        font-size: 0.875rem;
        color: $color-gray-80;
        font-weight: 500;
      }
    }
  }

  &__discount {
    margin: 2rem 0rem 2rem 0rem;
    text-align: center;

    & .header-2 {
      font-weight: 700;
      color: $color-gray-100;
      padding: 1rem 0rem 1rem 0rem;
      border-top: 1px solid $color-gray-20;
      letter-spacing: 4px;
    }

    & .discount-balance {
      padding: 1rem 0rem;
      border-top: 1px solid $color-gray-10;

      & .label {
        padding-bottom: 0.5rem;
        font-weight: 500;
      }

      & .balance {
        font-weight: 700;
      }
    }
  }
  &__details {
    & .gift-card-thank-you__detail-group {
      padding: 1rem;
      border-top: 1px solid $color-gray-20;
      & p {
        padding-bottom: 1rem;
        color: $color-black;
      }

      & .special-message {
        font-style: italic;
      }
    }
  }
}

@include breakpoint("lrg") {
  .gift-card-thank-you {
    padding-bottom: 4rem;
    justify-content: center;
    &__content {
      width: 67%;
    }

    &__discount,
    &__details {
      & h2 {
        padding: 1rem 0rem;
      }
    }

    &__discount {
      & .discount-balance {
        padding-bottom: 2rem;
        & .balance {
          font-size: 1.5rem;
        }
      }
    }
  }
}
