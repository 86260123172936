@import "../../variables/_color.scss";
@import "../../variables/_type.scss";
@import "../../variables/_layout.scss";

.login {
  & h1 {
    @include header-5;
    margin: 1rem auto 2rem auto;
    font-weight: $font-weight-light;
    text-align: center;
  }
  @include breakpoint("med") {
    box-shadow: $color-box-shadow-2;
    width: 50%;
    max-width: 32rem;
    margin: auto;
    padding: 1rem;
    border-radius: 4px;
    background-color: #fff;
  }
}
