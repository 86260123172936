@import "../../../variables/layout";

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.form-container {
  width: 100%;
  animation: fadeIn 0.25s ease-in-out;
}

@include breakpoint("lrg") {
  .form-container {
    width: 100%;
    margin-bottom: 1rem;
  }

  .form-field-row {
    display: flex;
    // border: 1px solid pink;
    justify-content: space-between;

    &__left {
      flex-basis: 50%;
    }

    &__right {
      flex-basis: 50%;
    }
  }

  .macro-row {
    display: flex;
  }
}
