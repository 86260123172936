@import "../../variables/type";
@import "../../variables/color";
@import "../../variables/layout";

.meal-add-to-cart {
  position: sticky;
  bottom: -1px;
  left: -1rem;
  z-index: 2200;
  background-color: #fff;
  padding: 1rem 1rem;
  margin: 1rem -1rem;
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
  overflow-x: hidden;

  &--sticky {
    box-shadow: $color-box-shadow-1;
  }

  &__quantity {
    display: none;
  }
}

@include breakpoint("med") {
  .meal-add-to-cart {
    justify-content: start;
    border-top: 1px solid $color-gray-10;
    position: relative;
    left: 0rem;
    margin: 1rem 0rem;
    padding: 1rem 0rem;

    &__quantity {
      display: block;
      align-self: center;
      color: $color-gray-60;
      font-size: 0.875rem;
    }

    & .brento-stepper {
      margin-right: 1rem;
    }

    & .MuiButton-root {
      width: 33%;
    }
  }
}
