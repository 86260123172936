@import "../../variables/_layout.scss";

.layout {
  position: relative;

  & .navbar {
    z-index: 2000;
  }

  & .standard-layout {
    position: relative;

    &__main {
      min-height: 70vh;
      margin-top: 5rem;
      position: relative;
      z-index: 1;
    }
  }

  @include breakpoint("lrg") {
    align-items: stretch;
    flex-direction: row;
    & .standard-layout {
      &__main {
        margin-top: 5rem;
        min-height: 78vh;
      }
    }
  }
}

// Admin Layout -------------- //
.admin-layout {
  & .admin-layout__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 1rem;
    margin-top: 4rem;
    min-height: 80vh;
  }

  & .admin-layout__primary {
    display: block;
    position: relative;
    padding: 1rem 0rem;
  }

  @include breakpoint("lrg") {
    & {
      align-items: stretch;
      flex-direction: row;
    }

    & .admin-layout__main {
      display: flex;
      width: auto;
      padding: 0rem;
      margin-top: 0rem;
      margin-left: 16rem;
      max-height: 100vh;
      overflow-y: scroll;
    }

    & .admin-layout__primary {
      flex-grow: 1;
      margin-right: calc(30vw + 2rem);
      max-width: $container-max-width;
    }

    & .admin-layout__drawer {
      flex-grow: 1;
      max-width: 30vw;
    }
  }
}

.container {
  max-width: $container-max-width;
  margin: auto;
  padding: 1rem;

  @include breakpoint("med") {
    padding: 2rem;
  }

  @include breakpoint("lrg") {
    padding: 0;
  }
}
