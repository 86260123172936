@import "../../../variables/color";
@import "../../../variables/type";
@import "../../../variables/layout";

.gift-card-checkout {
  padding: 2rem 0rem 1rem 0rem;

  &__field-group {
    padding: 1rem 0rem 1rem 0rem;

    & .header-5 {
      font-weight: 500;
      padding-bottom: 0.5rem;
      color: $color-gray-100;
    }

    & .body-2 {
      padding-bottom: 1.5rem;
      color: $color-gray-80;

      & b {
        color: $color-gray-100;
      }
    }
    & .character-count {
      font-size: 0.75rem;
      padding-top: 0.25rem;
      padding-left: 0.875rem;
      color: $color-gray-60;
    }
  }

  &__custom-amount-field {
    margin-top: 1rem;
  }

  &__field {
    padding-bottom: 1rem;

    & .MuiFormLabel-root {
      padding-bottom: 0.5rem;
      display: inline-block;
    }
  }

  &__cc-details-row {
    display: flex;
    justify-content: space-between;

    & .brento-text-input {
      flex-basis: calc(50% - 0.25rem);
    }
  }

  &__submit-panel {
    padding-top: 1rem;
    border-top: 1px solid $color-gray-10;

    & .send-date {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 1rem;

      & .label {
        padding-right: 0.5rem;
        color: $color-gray-80;
        padding-bottom: 0.5rem;
        font-weight: 500;
      }

      & .value {
        color: $color-gray-100;
        font-weight: 700;
      }
    }
    & .total {
      padding: 1rem 0rem;
      font-weight: 500;
      color: $color-gray-80;

      & span {
        font-weight: 700;
        color: $color-gray-100;
      }
    }
  }
}

@include breakpoint("lrg") {
  .gift-card-checkout {
    padding: 2rem 0rem 4rem 0rem;

    & .MuiFormGroup-row label {
      margin-right: 40px;
    }

    &__submit-panel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & .total {
        font-size: 1.25rem;
        padding-top: 0rem;
      }

      & .send-date {
        padding-bottom: 0rem;

        & .label {
          padding-bottom: 0rem;
        }
      }

      & .button-slot {
        flex-basis: 38%;
      }
    }
  }
}
