@import "../../../variables/layout";
@import "../../../variables/type";
@import "../../../variables/color";

.menu-category-template {
  &__header {
    padding: 1rem 0rem;
    .header-3 {
      font-weight: 500;
      color: $color-black;
    }
  }
  @include breakpoint("lrg") {
  }
}
