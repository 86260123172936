@import "../../../variables/layout";
@import "../../../variables/color";

.create-promo-code-form {
  width: 100%;

  &__field-group {
    width: 100%;
    padding: 1rem 0rem 2rem 0rem;

    & h2 {
      padding-bottom: 1rem;
      color: $color-gray-100;
      font-weight: 500;
    }

    & .supporting-text {
      margin-top: -0.5rem;
      padding-bottom: 1rem;
      font-size: 0.875rem;
      line-height: 1.5;
      color: $color-gray-80;
    }

    & .field-row {
      padding-bottom: 1.5rem;
      display: flex;

      &__right {
        width: 67%;
        margin-right: 1rem;
      }

      &__left {
        width: 33%;
      }
    }
  }

  & .team-member-emails {
    & .team-member-emails__email-item {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
    }
  }

  &__custom-name-field {
    padding: 1rem 0rem;
  }

  & .error {
    color: $color-error;
    padding: 1rem 0rem;
  }

  & .character-count {
    font-size: 0.75rem;
    color: $color-gray-80;
    padding: 0.5rem 0rem 1rem 0rem;
  }

  & .alert-message {
    margin-bottom: 1rem;
  }
}
