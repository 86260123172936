@import "../../../../variables/_layout.scss";
@import "../../../../variables/_type.scss";
@import "../../../../variables/_color.scss";

.order-manager-header {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    @include header-4;
    color: $color-black;
    margin-bottom: 0.5rem;
  }

  &__label {
    margin-bottom: 1rem;
    line-height: 1.5rem;
    color: $color-gray-80;
  }

  &__date-selection-panel {
    margin: 0rem -1rem 1rem -1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-color: $color-gray-05;
    width: calc(100% + 2rem);
  }

  &__csv-btn {
    height: 3rem;
    background-color: transparent;
    border: 1px solid $color-gray-40;
    color: $color-gray-60;
    padding: 0.5rem 1rem;
    border-radius: 2px;
    transition: all 0.125s ease-in-out;
    width: 100%;
    margin-top: 1rem;
    &:hover {
      color: $color-gray-100;
      border: 1px solid $color-gray-100;
      cursor: pointer;
    }
  }
}

@include breakpoint("lrg") {
  .order-manager-header {
    margin-top: 2rem;
    justify-content: space-between;
    align-items: baseline;

    &__date-selection-panel {
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: row;
      margin: 0rem 0rem 1rem 0rem;
      width: 100%;
      border-radius: 4px;
      padding: 1rem;

      & .MuiTextField-root {
        margin-right: 2rem;
      }
    }
  }
}
