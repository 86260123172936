@import "../../variables/color";
@import "../../variables/type";

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-8rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
}

@keyframes grow {
  from {
    opacity: 0;
    transform: scale(50%, 50%);
  }
  to {
    opacity: 1;
    transform: scale(100%, 100%);
  }
}

.shopping-cart {
  & .header-2 {
    margin-bottom: 2rem;
    font-family: $font-primary;
  }
}

.cart-empty-state {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.25s ease-in-out;
  padding-bottom: 4rem;

  &:before {
    content: "";
    position: absolute;
    left: auto;
    top: 0;
    width: 12rem;
    height: 12rem;
    border: 1px solid $color-gray-10;
    border-radius: 50%;
    z-index: 1;
    background-color: $color-gray-05;
  }

  &__img {
    position: relative;
    z-index: 2;
    width: 12rem;
    margin-bottom: 1rem;
  }

  &__text-content {
    animation: slideUp 0.5s ease-in-out 0.5s backwards;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    font-size: 1.25rem;
    line-height: 1.2em;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-family: $font-primary;
    color: $color-gray-100;
  }

  &__text {
    text-align: center;
    margin-bottom: 1.5rem;
    color: $color-gray-80;
    width: 85%;
  }

  & .MuiButton-outlined {
    animation: slideUp 0.5s ease-in-out 0.75s backwards;
  }
}

@include breakpoint("lrg") {
  .shopping-cart {
    & .header-2 {
      padding-top: 2rem;
    }

    &__main {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__sidebar {
      flex-basis: 33%;
      order: 2;
    }

    &__items {
      flex-basis: calc(67% - 3rem);
      border-top: 1px solid $color-gray-20;
    }
  }

  .cart-empty-state {
    &:before {
      animation: grow 0.5s ease-in-out 0.25s backwards;
    }

    &.cart-empty-state--available {
      .cart-empty-state__img {
        animation: slideIn 0.5s ease-in-out backwards;
      }
    }

    &.cart-empty-state--unavailable {
      .cart-empty-state__img {
        animation: slideUp 0.5s ease-in-out backwards;
      }
    }
  }
}
